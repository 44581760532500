import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Progress } from "reactstrap";
import { useEditor } from "../../hooks/editor.hooks";
import { useAuth } from "../../hooks/auth.hooks";
import { createMessageObject } from "../../helpers";
import firebase from "../../config/firebase";
const uuidv4 = require("uuid/v4");

const FindMatchModal = ({ isOpen, toggle }) => {
  const auth = useAuth();
  const { currentLesson, sendToNextLesson } = useEditor();
  const [progressValue, setProgressValue] = useState(0);

  const ref = firebase.database().ref("globalAlerts");
  const pushId = ref.push();

  useEffect(() => {
    const progressValueIncrement = 1;

    const interval = setInterval(() => {
      setProgressValue(progressValue => progressValue + progressValueIncrement);
    }, 1000);

    // Hacky fix to expire after we hit 100% on progress bar
    setTimeout(() => {
      expireInvite();
    }, 1000 * (100 / progressValueIncrement)); // 1000 * (100 / progressValueIncrement)

    handleSendGlobalInvite();

    return () => {
      clearInterval(interval);
      expireInvite();
      removeInviteAcceptanceListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSendGlobalInvite = () => {
    const inviteMessage = generateInviteMessage();
    ref.child(pushId.key).set(inviteMessage);
    listenForInviteAcceptance();
  };

  const listenForInviteAcceptance = () => {
    ref.child(pushId.key).on("value", snapshot => {
      if (snapshot.val() && snapshot.val().inviteAccepted) {
        sendToNextLesson(true, currentLesson, snapshot.val().id);
        ref.child(pushId.key).off();
        expireInvite();
      }
    });
  };

  const expireInvite = () => {
    ref.child(pushId.key).update({ inviteExpired: true });
  };

  const removeInviteAcceptanceListener = () => {
    ref.child(pushId.key).off();
  };

  const generateInviteMessage = () => {
    const input = `${
      auth.user.displayName
    } is looking for a partner to complete ${currentLesson.language.toUpperCase()} ${
      currentLesson.lessonTitleShort
    }`;

    const userId = auth.user.uid;

    return createMessageObject(
      auth.user.displayName,
      input,
      userId,
      true,
      false,
      false,
      currentLesson,
      uuidv4()
    );
  };

  return (
    <Modal className="finding-match-modal" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Finding Match...</ModalHeader>
      <ModalBody>
        {progressValue >= 100 ? (
          <p>
            Sorry, we couldn't find you a match... Please try again later or
            complete the lesson alone.
          </p>
        ) : (
          <Progress animated value={progressValue} />
        )}
      </ModalBody>
    </Modal>
  );
};

export default FindMatchModal;
