import React from "react";
import ProfileImage from "../../../ProfileImage";

const FriendListItem = ({ userId, username, onClick, unread }) => {
  return (
    <div className="container friend-item-wrapper">
      <div className="row">
        <div className="col-2">
          <ProfileImage userId={userId} unread={unread} />
        </div>
        <div className="col-5">
          <p className="text-left pt-3">{username}</p>
        </div>
        <div className="col-5 text-right">
          <button
            style={{ minWidth: 80 }}
            className="btn lt-grey-cta mr-2 mt-2"
            onClick={onClick}
          >
            Chat
          </button>
        </div>
      </div>
    </div>
  );
};

export default FriendListItem;
