import React, { useState, useContext, createContext } from "react";

// Contexts
const AlertContext = createContext();

// Providers
export const AlertProvider = ({ children }) => {
  const alert = useProvideAlert();
  return (
    <AlertContext.Provider value={alert}>{children}</AlertContext.Provider>
  );
};

// Hook thats exposed
export const useAlert = () => {
  return useContext(AlertContext);
};

const useProvideAlert = () => {
  const [unreadGlobalAlert, setUnreadGlobalAlert] = useState(false);
  const [unreadFriendMessage, setUnreadFriendMessage] = useState(false);

  // Return the user object and auth methods
  return {
    unreadGlobalAlert,
    setUnreadGlobalAlert,
    unreadFriendMessage,
    setUnreadFriendMessage
  };
};
