import React, { useState } from "react";
import { useEditor } from "../../../../../hooks/editor.hooks";
import { FormGroup, Label, Input } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";

const RoomSettings = () => {
  const {
    roomId,
    changeRoomReadWriteSettings,
    currentUserIsRoomOwner,
    isRoomPrivate,
    isForLesson
  } = useEditor();
  const [copied, setCopied] = useState(false);

  const handleReadWriteSettingUpdate = event => {
    event.preventDefault();
    const { value } = event.target;
    const isPrivate = value.toLowerCase() === "private" ? true : false;
    changeRoomReadWriteSettings(isPrivate);
  };

  return (
    <div className="container navy">
      {isForLesson ? null : (
        <div className="row mt-4">
          <div className="col-12">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">Room ID</span>
              </div>
              <input
                type="text"
                className="form-control"
                value={roomId}
                disabled
              />
              <div className="input-group-append">
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={() => setCopied(true)}
                >
                  <span
                    className="input-group-text"
                    style={{ cursor: "pointer" }}
                  >
                    Copy
                  </span>
                </CopyToClipboard>
              </div>
            </div>
            {copied ? <p className="navy mt-0">Copied</p> : null}
          </div>
        </div>
      )}

      {isForLesson ? null : (
        <div className="row">
          <div className="col-12">
            <FormGroup>
              <Label for="read-write-settings">Read & Write Settings</Label>
              <Input
                type="select"
                name="select"
                id="read-write-settings"
                onChange={handleReadWriteSettingUpdate}
                disabled={!currentUserIsRoomOwner}
                value={isRoomPrivate ? "Private" : "Public"}
              >
                <option>Public</option>
                <option>Private</option>
              </Input>
            </FormGroup>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoomSettings;
