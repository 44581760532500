import React, { useState } from "react";
import { useList } from "react-firebase-hooks/database";
import FriendsChat from "./FriendsChat";
import FriendListItem from "./FriendListItem";
import { getChatRef } from "../../../../helpers";
import { useAuth } from "../../../../hooks/auth.hooks";
import { useAlert } from "../../../../hooks/alert.hooks";
import firebase from "../../../../config/firebase";
import "./index.scss";

const FriendsList = () => {
  const auth = useAuth();
  const alert = useAlert();
  const [value, loading, error] = useList(
    firebase
      .database()
      .ref("friends")
      .child(auth.user.uid)
  );
  const [showChat, setShowChat] = useState(false);
  const [friendId, setFriendId] = useState("");
  const [friendUsername, setFriendUsername] = useState("");

  const handleUsernameClick = (userId, username) => {
    setFriendId(userId);
    setFriendUsername(username);
    setShowChat(true);
  };
  const [unread, setUnread] = useState(false);

  const handleShowFriendsClick = () => {
    setFriendId("");
    setShowChat(false);
  };

  const listenForNewMessage = userId => {
    // if (!auth || !auth.user) return;
    // const chatRefId = getChatRef(auth.user.uid, userId);
    // const ref = firebase
    //   .database()
    //   .ref("chats")
    //   .child(chatRefId);
    // ref.on("value", snapshot => {
    //   if (snapshot.val()) {
    //     console.log("rnning");
    //     setUnread(true);
    //     alert.setUnreadFriendMessage(true);
    //   }
    // });
  };

  if (showChat) {
    return (
      <FriendsChat
        userId={auth.user.uid}
        friendId={friendId}
        friendUsername={friendUsername}
        onClick={handleShowFriendsClick}
      />
    );
  } else {
    return (
      <>
        {error && <strong>Error: {error}</strong>}
        {loading && <span>Loading...</span>}
        {!loading && !value.length && (
          <p className="text-center mt-5">No friends yet</p>
        )}
        {!loading &&
          value &&
          value.map(item => {
            listenForNewMessage(item.val().userId);
            return (
              <FriendListItem
                key={item.key}
                userId={item.val().userId}
                username={item.val().username}
                unread={unread}
                onClick={() =>
                  handleUsernameClick(item.val().userId, item.val().username)
                }
              />
            );
          })}
      </>
    );
  }
};

export default FriendsList;
