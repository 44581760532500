import React from "react";
import { useForm } from "../../../hooks/form.hooks";
import { FORM_TYPES } from "../../../constants";
import Button from "../../../components/Button";
import { BUTTON_CONSTANTS } from "../../../constants";
import "./index.scss";

const BugForm = () => {
  const {
    inputs,
    handleInputChange,
    handleSubmit,
    isLoading,
    isSuccess,
    isFailure,
    failuredMessage
  } = useForm(FORM_TYPES.BUG_FORM);

  return (
    <div className="bug-form-component-wrapper shadow">
      <form className="bug-form pb-4" onSubmit={handleSubmit}>
        <div className="form-divider">
          <span>Report a bug</span>
        </div>
        <div className="form-group mb-4">
          <label htmlFor="bug">Report bug below</label>
          <textarea
            id="bug"
            name="bug"
            placeholder="Please describe the bug..."
            type="text"
            className="form-control text-input"
            onChange={handleInputChange}
            value={inputs.bug}
            required
          />
        </div>
        <Button
          buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
          buttonText={"Report Bug"}
          isLoading={isLoading}
        />
      </form>

      {isSuccess ? (
        <div class="alert alert-success" role="alert">
          Bug successfully reported!
        </div>
      ) : null}

      {isFailure ? (
        <div className="alert alert-danger" role="alert">
          {failuredMessage
            ? failuredMessage
            : "Something went wrong... Please try again."}
        </div>
      ) : null}
    </div>
  );
};

export default BugForm;
