import React, { useState, useEffect } from "react";
import Button from "../../../components/Button";
import ImageUploader from "../../../components/ImageUploader";
import defaultProfilePicture from "../../../assets/users/default-user-image.png";
import { useAuth } from "../../../hooks/auth.hooks";
import { useForm } from "../../../hooks/form.hooks";
import { BUTTON_CONSTANTS, FORM_TYPES } from "../../../constants";
import "./index.scss";

const UserInfoTab = () => {
  const [profileUrl, setProfileUrl] = useState("");
  const {
    inputs,
    handleInputChange,
    handleSubmit,
    isLoading,
    isSuccess,
    isFailure,
    failuredMessage
  } = useForm(FORM_TYPES.USERNAME_CHANGE_FORM);
  const auth = useAuth();

  useEffect(() => {
    if (auth.user && auth.user.photoURL) {
      setProfileUrl(auth.user.photoURL);
    } else {
      setProfileUrl(defaultProfilePicture);
    }
  }, [auth]);

  const updatePhotoUrl = url => {
    setProfileUrl(url);
  };

  return (
    <div className="user-info-tab-wrapper">
      <label className="pl-3" htmlFor="basic-url">
        Update your username:
      </label>
      <div className="input-group mb-3 col-12 col-lg-6">
        <input
          type="text"
          name="username"
          value={inputs.username}
          onChange={handleInputChange}
          className="form-control"
          placeholder={
            auth.user && auth.user.displayName
              ? auth.user.displayName
              : "Username"
          }
          aria-label="Recipient's username"
        />
        <div className="input-group-append">
          <Button
            styleProps={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
            buttonText={"Update Username"}
            onClick={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      </div>
      <div className="col-lg-6">
        <label htmlFor="basic-url">Update profile picture:</label>
        <ImageUploader updatePhotoUrl={url => updatePhotoUrl(url)} />
      </div>
      <div className="col-lg-6">
        <div
          className="profile-picture"
          style={{ backgroundImage: `url(${profileUrl})` }}
        ></div>
      </div>

      {isSuccess ? (
        <div className="alert alert-success mt-5" role="alert">
          Successfully updated username!
        </div>
      ) : null}

      {isFailure ? (
        <div className="alert alert-danger mt-5" role="alert">
          {failuredMessage
            ? failuredMessage
            : "Something went wrong... Please try again."}
        </div>
      ) : null}
    </div>
  );
};

export default UserInfoTab;
