import React from "react";
import Button from "../Button";
import { BUTTON_CONSTANTS } from "../../constants";
import { useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./index.scss";

const LoginModal = props => {
  const history = useHistory();

  return (
    <Modal
      className="login-modal-wrapper"
      isOpen={props.isOpen}
      toggle={props.toggle}
    >
      <ModalHeader toggle={props.toggle}>Please Login</ModalHeader>
      <ModalBody>
        <p className="mb-4">You need to be logged in to complete a lesson.</p>
        <Button
          styleProps={{ marginBottom: 10 }}
          buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
          buttonText={"Login"}
          onClick={() => history.push("/login")}
        />
        <Button
          buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
          buttonText={"Sign Up"}
          onClick={() => history.push("/signup")}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
          buttonText={"Cancel"}
          onClick={props.toggle}
        />
      </ModalFooter>
    </Modal>
  );
};

export default LoginModal;
