export const BUTTON_CONSTANTS = {
  GREY_TO_NAVY: "lt-grey-cta",
  WHITE_TO_GREY: "white-cta",
  MATCH_MAKING_CTA: "match-making-cta"
};

export const FORM_TYPES = {
  SIGN_UP_FORM: "sign_up_form",
  BUG_FORM: "bug_form",
  LOG_IN_FORM: "log_in_form",
  USERNAME_CHANGE_FORM: "username_change_form",
  DELETE_ACCOUNT_FORM: "delete_account_form"
};

export const LOG_TYPES = {
  ERROR: "error",
  LOG: "log"
};

export const LESSON_LANGUAGE = {
  HTML: "html",
  CSS: "css"
};

export const LESSON_TYPES = {
  SOLO: "solo",
  WITH_A_FRIEND: "friend",
  MATCH_MAKING: "match_making"
};

export const TOAST_LEVEL = {
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  SUCCESS: "success"
};
