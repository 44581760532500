import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";

const SideMenu = ({ children, open, onClick }) => {
  return (
    <div className={`shadow side-menu-wrapper ${open ? "" : "closed"}`}>
      <FontAwesomeIcon
        style={{ cursor: "pointer" }}
        onClick={onClick}
        icon={faTimes}
        size="2x"
        className="navy ml-3 mt-3"
      />
      {children}
    </div>
  );
};

export default SideMenu;
