import React, { useState, useEffect } from "react";
import CodeEditor from "./components/CodeEditor";
import RightPanel from "./components/RightPanel";
import LessonsPanel from "./components/LessonsPanel";
import { useEditor } from "../../hooks/editor.hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faListAlt } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";

const CodeEditorPage = () => {
  const {
    isForLesson,
    getRoomId,
    setRoomId,
    setIsForLesson,
    getRoomType
  } = useEditor();
  const [rightPanelOpen, setRightPanelOpen] = useState(false);
  const [lessonsPanelOpen, setlessonsPanelOpen] = useState(true);

  useEffect(() => {
    setRoomId(getRoomId());
    // Used to determine if lesson panel should display.
    setIsForLesson(getRoomType());
  }, [getRoomId, getRoomType, setIsForLesson, setRoomId]);

  return (
    <>
      <CodeEditor />
      <RightPanel
        open={rightPanelOpen}
        onClick={() => setRightPanelOpen(false)}
      />
      <button
        className="hamburger-button right-panel-burger-button shadow"
        onClick={() => setRightPanelOpen(true)}
      >
        <FontAwesomeIcon icon={faCogs} size="lg" />
      </button>
      {isForLesson ? (
        <>
          <LessonsPanel
            open={lessonsPanelOpen}
            onClick={() => setlessonsPanelOpen(false)}
          />
          <button
            className="hamburger-button lessons-panel-burger-button shadow"
            onClick={() => setlessonsPanelOpen(true)}
          >
            <FontAwesomeIcon icon={faListAlt} size="lg" />
          </button>
        </>
      ) : null}
    </>
  );
};

export default CodeEditorPage;
