import React, { useState } from "react";
import { useForm } from "../../hooks/form.hooks";
import Button from "../../components/Button";
import ForgotPasswordModal from "./components/ForgotPasswordModal";
import { Link } from "react-router-dom";
import { BUTTON_CONSTANTS, FORM_TYPES } from "../../constants";
import "./index.scss";

const LoginForm = () => {
  const [modal, setModal] = useState(false);
  const {
    inputs,
    handleInputChange,
    handleSubmit,
    isLoading,
    isSuccess,
    isFailure,
    failuredMessage
  } = useForm(FORM_TYPES.LOG_IN_FORM);

  const handleModalToggle = e => {
    if (e) e.preventDefault();
    setModal(!modal);
  };

  return (
    <div className="login-form-component-wrapper shadow">
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="form-divider">
          <span>Login</span>
        </div>
        <div className="form-group mb-3">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            name="email"
            placeholder="Enter your email"
            type="email"
            className="form-control text-input"
            onChange={handleInputChange}
            value={inputs.email}
            required
          />
        </div>
        <div className="form-group mb-1">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            name="password"
            placeholder="Enter your password"
            type="password"
            className="form-control text-input"
            onChange={handleInputChange}
            value={inputs.password}
            required
          />
        </div>
        <p className="mt-0 mb-1">
          <span className="btn btn-link p-0" onClick={handleModalToggle}>
            Forgot password?
          </span>
        </p>
        <Button
          isLoading={isLoading}
          buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
          buttonText={"Log In"}
        />
        <p className="text-center mt-3">
          <Link to="courses">Go to Coduo without signing up</Link>
        </p>
      </form>
      <ForgotPasswordModal isOpen={modal} toggle={handleModalToggle} />

      {isSuccess ? (
        <div className="alert alert-success" role="alert">
          Successfully logged in!
        </div>
      ) : null}

      {isFailure ? (
        <div className="alert alert-danger" role="alert">
          {failuredMessage
            ? failuredMessage
            : "Something went wrong... Please try again."}
        </div>
      ) : null}
    </div>
  );
};

export default LoginForm;
