import { LOG_TYPES, LESSON_LANGUAGE, TOAST_LEVEL } from "../constants";
import {
  lessonData_HTML,
  lessonData_CSS,
  coursesAvailable
} from "../config/course-data";
import { toast } from "react-toastify";
import moment from "moment";
const uuidv4 = require("uuid/v4");

/**
 * Helper function that fires off a toast alert using the
 * message and level provided.
 *
 * @param {string} message Message to be displayed in toast.
 * @param {TOAST_LEVEL} level Level for the toast (i.e ERROR, INFO, etc.).
 */
export const fireToast = (message, level) => {
  if (level === TOAST_LEVEL.ERROR) toast.error(message);
  if (level === TOAST_LEVEL.INFO) toast.info(message);
  if (level === TOAST_LEVEL.WARNING) toast.warn(message);
  if (level === TOAST_LEVEL.SUCCESS) toast.success(message);
};

/**
 * Helper function that gets the lesson data based on the lesson
 * language and the lesson number.
 *
 * @param {string} lessonLanguage Language the lesson is in (i.e HTML).
 * @param {string} lessonNumber Lesson number.
 */
export const getLessonData = (lessonLanguage, lessonNumber) => {
  let lessonData = {};

  switch (lessonLanguage) {
    case LESSON_LANGUAGE.HTML:
      lessonData_HTML
        .filter(lesson => Number(lesson.lessonNumber) === lessonNumber)
        .map(lesson => (lessonData = lesson));
      break;

    case LESSON_LANGUAGE.CSS:
      lessonData_CSS
        .filter(lesson => Number(lesson.lessonNumber) === lessonNumber)
        .map(lesson => (lessonData = lesson));
      break;

    default:
      break;
  }

  return lessonData;
};

/**
 * Fetches URL parameter based on key provided.
 *
 * @param {string} param Key for param to get.
 */
export const getParams = param => {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
};

export const getChatRef = (userIdOne, userIdTwo) => {
  return userIdOne > userIdTwo
    ? `${userIdOne}-${userIdTwo}`
    : `${userIdTwo}-${userIdOne}`;
};

export const loggerService = (logString, error, severity) => {
  switch (severity) {
    case LOG_TYPES.LOG:
      console.log(logString);
      break;

    case LOG_TYPES.ERROR:
      console.error(logString, error);
      break;

    default:
      console.error("Uncaught error...");
      break;
  }
};

export const createMessageObject = (
  username,
  text,
  userId,
  isLessonInvite,
  inviteExpired,
  inviteAccepted,
  lessonData,
  id
) => {
  const createdAt = moment().format("LLL");
  return {
    id: id || uuidv4(),
    username,
    text,
    userId,
    createdAt,
    isLessonInvite,
    inviteExpired,
    inviteAccepted,
    lessonData
  };
};

/**
 * Takes in current lesson number and lesson language
 * and returns the next lesson data.
 *
 * @param {number} lessonNumber Number of current lesson.
 * @param {string} language Language of the lesson.
 * @return {object} Data for the next lesson.
 */
export const getNextLessonData = (lessonNumber, language, lessonType) => {
  const lessonData =
    language === LESSON_LANGUAGE.HTML ? lessonData_HTML : lessonData_CSS;
  const nextLessonNumber = Number(lessonNumber) + 1;
  const nextLessonData = lessonData.filter(
    lesson => lesson.lessonNumber === nextLessonNumber
  );

  return {
    ...nextLessonData[0],
    lessonType,
    language
  };
};

/**
 * Takes in a lesson object and generates obj in correct shape for
 * to be used as a history.push arg when going to new lesson.
 *
 * @param {object} lessonData Lesson data used to generate object.
 */
export const generateLessonHistoryObject = lessonData => {
  return {
    videoId: lessonData.videoId,
    language: lessonData.language,
    lessonNumber: lessonData.lessonNumber,
    lessonDescription: lessonData.lessonDescription,
    lessonTitleLong: lessonData.lessonTitleLong,
    lessonType: lessonData.lessonType,
    videoOnly: lessonData.videoOnly,
    id: uuidv4()
  };
};

/**
 * Takes in a language and returns the total number of
 * lessons for a course.
 *
 * @param {string} lessonLanguage Language of the course.
 * @returns {number} Number of lessons for a given course.
 */
export const getLanguageLessonsCount = lessonLanguage => {
  const course = coursesAvailable.filter(
    courses => courses.language === lessonLanguage
  )[0];
  return course.numberOfLessons;
};

/**
 * Takes in lesson language and total completed lessons for that language
 * and returns a score for that user in the given language.
 *
 * @param {string} language The language of the course.
 * @param {number} completedLessonCount The number of completed lessons.
 * @returns {number} The users score for the given language.
 */
export const generateUserCourseScore = (language, completedLessonCount) => {
  let totalLessonCount = 1;
  if (language === LESSON_LANGUAGE.HTML)
    totalLessonCount = getLanguageLessonsCount(language);
  if (language === LESSON_LANGUAGE.CSS)
    totalLessonCount = getLanguageLessonsCount(language);

  /**
   * 0% - 9% = 1
   * 10% - 19% = 2
   * 20% - 29% = 3
   * 30% - 39% = 4
   * 40% - 49% = 5
   * 50% - 59% = 6
   * 60% - 69% = 7
   * 70% - 79% = 8
   * 80% - 89% = 9
   * 90% - 100% = 10
   */

  let percent = (completedLessonCount / totalLessonCount) * 100;
  percent = percent.toFixed();

  let score = 0;
  if (percent >= 0 && percent <= 9) score = 1;
  if (percent >= 10 && percent <= 19) score = 2;
  if (percent >= 20 && percent <= 29) score = 3;
  if (percent >= 30 && percent <= 39) score = 4;
  if (percent >= 40 && percent <= 49) score = 5;
  if (percent >= 50 && percent <= 59) score = 6;
  if (percent >= 60 && percent <= 69) score = 7;
  if (percent >= 70 && percent <= 79) score = 8;
  if (percent >= 80 && percent <= 89) score = 9;
  if (percent >= 90 && percent <= 100) score = 10;

  return score;
};
