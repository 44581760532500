import React, { useState } from "react";
import { useAuth } from "../../../hooks/auth.hooks";
import Button from "../../Button";
import { BUTTON_CONSTANTS } from "../../../constants";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const ForgotPasswordModal = props => {
  const auth = useAuth();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);

  const handlePasswordReset = async event => {
    if (event) event.preventDefault();
    setIsLoading(true);

    try {
      await auth.sendPasswordResetEmail(email);
      setIsSuccess(true);
      setIsLoading(false);
    } catch (error) {
      setIsFailure(true);
      setIsLoading(false);
      console.error("Error when resetting password...");
    }
  };

  const handleInputChange = event => {
    event.persist();
    setIsSuccess(false);
    setIsFailure(false);
    setEmail(event.target.value);
  };

  return (
    <Modal
      className="login-modal-wrapper"
      isOpen={props.isOpen}
      toggle={props.toggle}
    >
      <ModalHeader toggle={props.toggle}>Reset Password</ModalHeader>
      <ModalBody>
        <form onSubmit={handlePasswordReset}>
          <div className="form-group mb-3">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              name="email"
              placeholder="Enter your email"
              type="email"
              className="form-control text-input"
              onChange={handleInputChange}
              value={email}
              required
            />
          </div>
          <Button
            buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
            buttonText={"Reset Password"}
            isLoading={isLoading}
          />
        </form>

        {isSuccess ? (
          <div className="alert alert-success mt-3" role="alert">
            Successfully registered!
          </div>
        ) : null}

        {isFailure ? (
          <div className="alert alert-danger mt-3" role="alert">
            Something went wrong... Please try again.
          </div>
        ) : null}
      </ModalBody>
    </Modal>
  );
};

export default ForgotPasswordModal;
