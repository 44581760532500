import React, { useState } from "react";
import { useAuth } from "../../../../hooks/auth.hooks";
import firebase from "../../../../config/firebase";
import SearchResultsModal from "../../../SearchResultsModal";
import { LOG_TYPES } from "../../../../constants";
import { loggerService } from "../../../../helpers";

const NavSearch = () => {
  const auth = useAuth();
  const [modal, setModal] = useState(false);
  const [query, setQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const handleLessonClick = () => {
    setModal(!modal);
  };

  const handleInputChange = event => {
    setQuery(event.target.value);
  };

  const handleSearch = async event => {
    if (event) event.preventDefault();
    if (!auth.user || !query) return;

    const searchRef = firebase.database().ref("search/queries");
    const searchId = searchRef.push();
    try {
      await searchId.set({ query });

      // For results we must listen for results node to be updated
      // to be able to fetch the result
      const resultsRef = firebase
        .database()
        .ref("search/results")
        .child(searchId.key);
      resultsRef.on("value", async snap => {
        if (snap.val()) {
          const snapshot = await resultsRef.once("value");
          const searchResults = snapshot.val();

          // open modal and display results
          setModal(true);
          setSearchResult(searchResults);

          // Remove listener once we have our result
          resultsRef.off("value");
        }
      });
    } catch (error) {
      loggerService("Error when searching...", error, LOG_TYPES.ERROR);
    }
  };

  return (
    <>
      <form className="form-inline my-2 my-lg-0 d-none d-md-block">
        <input
          className="form-control mr-2"
          type="search"
          placeholder="Search"
          aria-label="Search"
          onChange={handleInputChange}
          value={query}
        />
        <button onClick={handleSearch} className="btn lt-grey-cta my-2 my-sm-0">
          Search
        </button>
      </form>
      <SearchResultsModal
        isOpen={modal}
        toggle={handleLessonClick}
        data={searchResult}
      />
    </>
  );
};

export default NavSearch;
