import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faUserFriends,
  faExclamationCircle,
  faMinus,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import { useAlert } from "../../../../hooks/alert.hooks";
import classnames from "classnames";
import "./index.scss";

const Tabs = ({
  activeTab,
  toggle,
  toggleMinimize,
  isListMinimized,
  requestCount
}) => {
  const alerts = useAlert();

  console.log("alerts here", alerts);
  return (
    <Nav className="tabs-wrapper" tabs>
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === "1" })}
          onClick={() => {
            toggle("1");
          }}
        >
          <FontAwesomeIcon icon={faComments} />
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === "2" })}
          onClick={() => {
            toggle("2");
          }}
        >
          <FontAwesomeIcon icon={faUserFriends} />
          {requestCount > 0 ? (
            <span className="badge badge-danger">{requestCount}</span>
          ) : null}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === "3" })}
          onClick={() => {
            toggle("3");
            alerts.setUnreadGlobalAlert(false);
          }}
        >
          <FontAwesomeIcon icon={faExclamationCircle} />
          {alerts.unreadGlobalAlert ? (
            <span className="badge badge-danger">!</span>
          ) : null}
        </NavLink>
      </NavItem>
      <NavItem onClick={toggleMinimize} className="mt-2 chat-toggle">
        <FontAwesomeIcon icon={isListMinimized ? faPlus : faMinus} />
      </NavItem>
    </Nav>
  );
};

export default Tabs;
