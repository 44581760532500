import React from "react";
import { useList } from "react-firebase-hooks/database";
import firebase from "../../../../config/firebase";
import ChatHeader from "../../../ChatContainer/components/ChatHeader";
import ChatContainer from "../../../ChatContainer";
import ChatInput from "../../../ChatContainer/components/ChatInput";
import { getChatRef } from "../../../../helpers";

const FriendsChat = ({ userId, friendId, friendUsername, onClick }) => {
  const chatRefId = getChatRef(friendId, userId);
  const chatRef = firebase
    .database()
    .ref("chats")
    .child(chatRefId);
  const [value] = useList(chatRef);

  return (
    <>
      <ChatHeader onClick={onClick} friendUsername={friendUsername} />
      <ChatContainer messages={value} />
      <ChatInput chatRef={chatRef} />
    </>
  );
};

export default FriendsChat;
