import React from "react";

const MediaBody = props => {
  return (
    <div className="media mb-4">
      <span style={{ width: 40, textAlign: "center" }}>{props.faIcon}</span>
      <div className="media-body ml-2">
        <h5>{props.header}</h5>
        {props.body}
      </div>
    </div>
  );
};

export default MediaBody;
