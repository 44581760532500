import React from "react";
import SideMenu from "../../../../components/SideMenu";
import RoomChat from "./RoomChat";
import RoomSettings from "./RoomSettings";

const RightPanel = ({ open, onClick }) => {
  return (
    <SideMenu open={open} onClick={onClick}>
      <RoomSettings />
      <RoomChat />
    </SideMenu>
  );
};

export default RightPanel;
