import React from "react";
import AddUserButton from "./components/AddUserButton";
import { useAuth } from "../../hooks/auth.hooks";
import firebase from "../../config/firebase";
import ProfileImage from "../ProfileImage";
import { loggerService } from "../../helpers";
import { BUTTON_CONSTANTS, LOG_TYPES } from "../../constants";
import "./index.scss";

// DB Structure:
//
//  -pendingFriendRequests
//    - uid
//      - incoming
//        - friend_id1
//        - friend_id2
//      - outgoing
//        - friend_id3
//        - friend_id4

const UserList = props => {
  const auth = useAuth();

  const handleAddFriend = friendId => {
    const rootRef = firebase.database().ref("pendingFriendRequests");

    // update current user
    const currentUserRef = rootRef.child(auth.user.uid);
    const currentUserOutgoingRef = currentUserRef.child("outgoing");
    currentUserOutgoingRef
      .update({ [friendId]: friendId }, () => {
        loggerService("Updated outoging ref...", null, LOG_TYPES.LOG);
      })
      .catch(error => {
        loggerService(
          "Error when sending current user request...",
          error,
          LOG_TYPES.ERROR
        );
      });

    // update opposing user
    const friendRef = rootRef.child(friendId);
    const friendIncomingRef = friendRef.child("incoming");
    friendIncomingRef
      .update({ [auth.user.uid]: auth.user.uid }, () => {
        loggerService("Updated incoming ref...", null, LOG_TYPES.LOG);
      })
      .catch(error => {
        loggerService(
          "Error when setting opposing request...",
          error,
          LOG_TYPES.ERROR
        );
      });
  };

  return (
    <ul className="user-list-wrapper list-unstyled">
      {props.data
        .filter(item => item.objectID !== auth.user.uid)
        .map((item, index) => (
          <li key={index}>
            <div className="row">
              <div className="col-7">
                <ProfileImage userId={item.objectID} />
                <p className="username">{item.username}</p>
              </div>
              <div className="col-5">
                <AddUserButton
                  styleProps={{ marginTop: 6 }}
                  buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
                  onClick={() => handleAddFriend(item.objectID)}
                  userId={item.objectID}
                />
              </div>
            </div>
          </li>
        ))}
    </ul>
  );
};

export default UserList;
