import React from "react";
import { useAuth } from "../../../../hooks/auth.hooks";
import { useHistory } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import Button from "../../../Button";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug } from "@fortawesome/free-solid-svg-icons";
import { BUTTON_CONSTANTS } from "../../../../constants";
import "./index.scss";

const NavLinks = props => {
  const auth = useAuth();
  const history = useHistory();
  const { user, currentUrlPath } = props;

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      history.push("/");
    } catch (error) {
      console.error("Error when signing out...", error);
    }
  };

  return (
    <Nav
      className={`ml-auto ${currentUrlPath !== "/" ? "white-links" : null}`}
      navbar
    >
      <NavItem className="mr-2">
        <Link className="nav-link" to="/courses">
          Courses
        </Link>
      </NavItem>
      <NavItem className="mr-2">
        <Link className="nav-link" to="/code-editor">
          Code Editor
        </Link>
      </NavItem>
      {user && !user.isAnonymous ? (
        <React.Fragment>
          <NavItem>
            <Link className="nav-link" to="/settings">
              Settings
            </Link>
          </NavItem>
          <NavItem>
            <button onClick={handleSignOut} className="btn lt-grey-cta">
              Log Out
            </button>
          </NavItem>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <NavItem className="mr-2">
            <Link className="nav-link" to="/login">
              <Button
                buttonStyle={BUTTON_CONSTANTS.WHITE_TO_GREY}
                buttonText={"Login"}
              />
            </Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/signup">
              <Button
                buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
                buttonText={"Sign Up"}
              />
            </Link>
          </NavItem>
        </React.Fragment>
      )}
      <NavItem className="ml-2">
        <Link className="nav-link" to="/report-bug">
          <FontAwesomeIcon icon={faBug} size={"lg"} />
        </Link>
      </NavItem>
    </Nav>
  );
};

export default NavLinks;
