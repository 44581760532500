import React, { useState, useEffect } from "react";
import firebase from "../../../../config/firebase";

/**
 * For each user being rendered in the friends list
 * this component recieves the friends user ID and
 * looks to see if they have an incoming alert from
 * the current user. i.e:
 * pendingFriendRequests/{friendId}/incoming/id:{user.uid}.
 *
 * @param {string} props.userId UID of each friend being rendered in list
 * @param {object} props.styleProps Style object
 * @param {function} props.onClick On click callback name
 * @param {string} props.buttonStyle Style object applied to element
 */
const AddUserButton = props => {
  const [requestPending, setRequestPending] = useState(false);
  const rootRef = firebase.database().ref("pendingFriendRequests");
  const userRef = rootRef.child(props.userId).child("incoming");

  useEffect(() => {
    userRef.on("value", snapshot => {
      if (snapshot.val()) setRequestPending(true);
    });

    return () => userRef.off("value");
  }, [userRef]);

  return (
    <p className="text-center mb-0">
      <button
        style={props.styleProps}
        onClick={props.onClick}
        type="submit"
        className={`btn ${props.buttonStyle}`}
        disabled={requestPending}
      >
        {requestPending ? "Request Pending" : "Add Friend"}
      </button>
    </p>
  );
};

export default AddUserButton;
