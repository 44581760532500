import React, { useState, useEffect } from "react";
import firebase from "../../config/firebase";
import DefaultProfilePic from "../../assets/users/default-user-image.png";
import "./index.scss";

const ProfileImage = props => {
  const [profileImage, setProfileImage] = useState(DefaultProfilePic);

  useEffect(() => {
    if (props && props.userId) {
      const rootRef = firebase
        .database()
        .ref("userInfo")
        .child(props.userId)
        .child("profileImageUrl");
      rootRef.once("value", snapshot => {
        if (snapshot.val()) {
          setProfileImage(snapshot.val());
        }
      });
    }
  }, [props]);

  return (
    <div
      className="profile-picture-wrapper"
      style={{
        backgroundImage: `url(${profileImage})`,
        borderColor: `${props.unread ? "red" : null}`
      }}
    ></div>
  );
};

export default ProfileImage;
