import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const BreadCrumbs = props => {
  const { language } = props;
  return (
    <React.Fragment>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <Link className="breadcrumb-item" to="/courses">
            Courses
          </Link>
          <Link className="breadcrumb-item active" to={`/courses/${language}`}>
            {language.toUpperCase()}
          </Link>
        </ol>
      </nav>
      <hr className="mt-0" />
    </React.Fragment>
  );
};

export default BreadCrumbs;
