import React from "react";
import ProfileImage from "../../ProfileImage";
import { useList } from "react-firebase-hooks/database";
import { useEditor } from "../../../hooks/editor.hooks";
import firebase from "../../../config/firebase";
import Button from "../../Button";
import { BUTTON_CONSTANTS } from "../../../constants";

const FriendsList = ({ uid }) => {
  const { handlePickAFriend } = useEditor();
  const [value, loading, error] = useList(
    firebase
      .database()
      .ref("friends")
      .child(uid)
  );

  return (
    <>
      {error && <strong>Error: {error}</strong>}
      {loading && <span className="text-center">Loading...</span>}
      {!loading && !value.length && (
        <p className="text-center mt-5">No friend requests yet</p>
      )}
      {!loading && value && (
        <ul className="user-list-wrapper list-unstyled">
          {value.map((item, index) => {
            return (
              <li key={index}>
                <div className="row">
                  <div className="col-7">
                    <ProfileImage userId={item.val().userId} />
                    <p className="username">{item.val().username}</p>
                  </div>
                  <div className="col-5">
                    <Button
                      styleProps={{ marginTop: 16 }}
                      buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
                      onClick={() => handlePickAFriend(item.val().userId)}
                      buttonText={"Invite Friend"}
                    />
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default FriendsList;
