import React from "react";
import { useForm } from "../../hooks/form.hooks";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import { BUTTON_CONSTANTS, FORM_TYPES } from "../../constants";
import "./index.scss";

const SignupForm = () => {
  const {
    inputs,
    handleInputChange,
    handleSubmit,
    isLoading,
    isSuccess,
    isFailure,
    failuredMessage
  } = useForm(FORM_TYPES.SIGN_UP_FORM);

  return (
    <div className="signup-form-component-wrapper shadow">
      <form className="signup-form" onSubmit={handleSubmit}>
        <div className="form-divider">
          <span>Sign Up by Email</span>
        </div>
        <div className="form-group mb-3">
          <label htmlFor="username">Username</label>
          <input
            id="username"
            name="username"
            placeholder="Enter your username"
            type="text"
            className="form-control text-input"
            onChange={handleInputChange}
            value={inputs.username}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            name="email"
            placeholder="Enter your email"
            type="email"
            className="form-control text-input"
            onChange={handleInputChange}
            value={inputs.email}
            required
          />
        </div>
        <div className="form-group mb-4">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            name="password"
            placeholder="Enter your password"
            type="password"
            className="form-control text-input"
            onChange={handleInputChange}
            value={inputs.password}
            required
          />
        </div>
        <Button
          buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
          buttonText={"Sign Up"}
          isLoading={isLoading}
        />
        <p className="text-center mt-3">
          <Link to="courses">Go to Coduo without signing up</Link>
        </p>
      </form>

      {isSuccess ? (
        <div className="alert alert-success" role="alert">
          Successfully registered!
        </div>
      ) : null}

      {isFailure ? (
        <div className="alert alert-danger" role="alert">
          {failuredMessage
            ? failuredMessage
            : "Something went wrong... Please try again."}
        </div>
      ) : null}
    </div>
  );
};

export default SignupForm;
