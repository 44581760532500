import React, { useState, useEffect, useRef } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import { useHistory } from "react-router-dom";
import { useEditor } from "../../../../hooks/editor.hooks";
import { getParams, getLessonData } from "../../../../helpers";
import SplitPane from "react-split-pane";
import LockedEditorModal from "../../../../components/LockedEditorModal";
import "./index.scss";

const CodeEditor = () => {
  const [html, setHtml] = useState("");
  const [css, setCss] = useState("");
  const [js, setJs] = useState("");
  const [showLockedEditorModal, setShowLockedEditorModal] = useState(false);
  const history = useHistory();
  const {
    roomId,
    roomRef,
    isNewEditorSession,
    isRoomPrivate,
    currentUserIsRoomOwner
  } = useEditor();

  const iframeRef = useRef(null);

  useEffect(() => {
    const lessonType = getParams("lesson_type");
    if (lessonType) {
      console.log("running");
      const lessonLanguage = getParams("language");
      const lessonNumber = getParams("lesson_number");
      const lessonData = getLessonData(
        lessonLanguage.toUpperCase(),
        Number(lessonNumber)
      );
      console.log("lesson data", lessonData);
    }
  }, []);

  useEffect(() => {
    if (!roomId | !roomRef) return;

    if (isNewEditorSession) history.replace(`/code-editor?room_id=${roomId}`); // add room_id param to the url

    const htmlRef = roomRef.child("html");
    const cssRef = roomRef.child("css");
    const jsRef = roomRef.child("js");

    htmlRef.on("value", snapshot => {
      setHtml(snapshot.val());
      runCode();
    });

    cssRef.on("value", snapshot => {
      setCss(snapshot.val());
      runCode();
    });

    jsRef.on("value", snapshot => {
      setJs(snapshot.val());
      runCode();
    });

    return () => {
      htmlRef.off();
      cssRef.off();
      jsRef.off();
    };
  });

  const runCode = () => {
    const iframe = iframeRef.current;
    const document = iframe.contentDocument;
    const documentContents = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <meta http-equiv="X-UA-Compatible" content="ie=edge">
          <title>Document</title>
          <style>
            ${css}
          </style>
        </head>
        <body>
          ${html === null ? "" : html}
          <script type="text/javascript">
            ${js === null ? "" : js}
          </script>
        </body>
        </html>
        `;

    document.open();
    document.write(documentContents);
    document.close();
  };

  const handleEditorChange = (language, content) => {
    // is not permitted to write
    if (isRoomPrivate && !currentUserIsRoomOwner) {
      setShowLockedEditorModal(true);
    } else {
      updateCodeInDb(language, content);
    }
  };

  const updateCodeInDb = (language, content) => {
    roomRef.update({
      [language]: content
    });
  };

  const codeMirrorOptions = {
    theme: "material",
    lineNumbers: true,
    scrollbarStyle: null,
    lineWrapping: true
  };

  return (
    <>
      <SplitPane split="vertical">
        <div>
          <h5 className="code-editor-title mb-0 pl-3 pt-1 white">HTML</h5>
          <CodeMirror
            value={html}
            options={{
              mode: "htmlmixed",
              ...codeMirrorOptions
            }}
            onBeforeChange={(editor, data, html) => {
              handleEditorChange("html", html);
            }}
          />
          <h5 className="code-editor-title mb-0 pl-3 pt-1 white">CSS</h5>
          <CodeMirror
            value={css}
            options={{
              mode: "css",
              ...codeMirrorOptions
            }}
            onBeforeChange={(editor, data, css) => {
              handleEditorChange("css", css);
            }}
          />
          <h5 className="code-editor-title mb-0 pl-3 pt-1 white">JS</h5>
          <CodeMirror
            value={js}
            options={{
              mode: "javascript",
              ...codeMirrorOptions
            }}
            onBeforeChange={(editor, data, js) => {
              handleEditorChange("js", js);
            }}
          />
        </div>
        <div>
          <h5 className="ml-2 pt-1 mb-0 lt-grey">PREVIEW</h5>
          <div className="iframe-wrapper">
            <iframe title="result" ref={iframeRef} />
          </div>
        </div>
      </SplitPane>
      <LockedEditorModal
        isOpen={showLockedEditorModal}
        toggle={() => setShowLockedEditorModal(!showLockedEditorModal)}
      />
    </>
  );
};

export default CodeEditor;
