import React, { useState } from "react";
import { useAuth } from "../../../hooks/auth.hooks";
import LoginModal from "../../../components/LoginModal";
import MatchMakingModal from "../../../components/MatchMakingModal";
import LessonCompletionIcon from "./LessonCompletionIcon";

const LessonPanel = ({ lesson }) => {
  const auth = useAuth();
  const [loginModal, setLoginModal] = useState(false);
  const [matchMakingModal, setMatchMakingModal] = useState(false);

  const handleLessonClick = () => {
    if (auth && auth.user && auth.user.isAnonymous) setLoginModal(!loginModal);
    else setMatchMakingModal(!matchMakingModal);
  };

  return (
    <div className="lesson-pannel-wrapper mb-5">
      <div className="container">
        <div className="row">
          <div className="col-2 col-lg-1 text-center lesson-completion-icon">
            <LessonCompletionIcon
              lessonName={lesson.lessonName}
              lessonLanguage={lesson.language}
            />
          </div>
          <div className="col-10 col-lg-11">
            <div className="panel" onClick={handleLessonClick}>
              <div className="row mb-2">
                <div className="col-12 col-md-8">
                  <h2>{lesson.lessonTitleLong}</h2>
                </div>
                <div className="col-12 col-md-4">
                  <h4 className="text-md-right">{lesson.lessonSkillLevel}</h4>
                </div>
              </div>
              <p>{lesson.lessonDescription}</p>
            </div>
          </div>
        </div>
      </div>
      {/* Login Modal */}
      {loginModal ? (
        <LoginModal
          isOpen={loginModal}
          toggle={() => setLoginModal(!loginModal)}
        />
      ) : null}
      {/* Match Making Modal */}
      {matchMakingModal ? (
        <MatchMakingModal
          isOpen={matchMakingModal}
          lesson={lesson}
          toggle={() => setMatchMakingModal(!matchMakingModal)}
        />
      ) : null}
    </div>
  );
};

export default LessonPanel;
