import React, { useState, useEffect } from "react";
import { useAuth } from "../../../hooks/auth.hooks";
import {
  generateUserCourseScore,
  getLanguageLessonsCount
} from "../../../helpers";
import Loader from "react-loader-spinner";
import firebase from "../../../config/firebase";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const LevelBox = ({ lessonLanguage }) => {
  const auth = useAuth();
  const [lessonCompleteCount, setLessonCompleteCount] = useState(0);
  const [userScore, setUserScore] = useState(0);
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    if (!auth || !auth.user) return;

    const rootRef = firebase.database().ref("lessonCompletions");
    const lessonCompletionsRef = rootRef
      .child(auth.user.uid)
      .child(lessonLanguage);

    lessonCompletionsRef.once("value", snapshot => {
      const snap = snapshot.val();
      if (snap) {
        const snapCount = Object.keys(snap).length;
        setLessonCompleteCount(snapCount);
        setUserScore(generateUserCourseScore(lessonLanguage, snapCount));
        setShowSpinner(false);
      }
    });
  }, [auth, lessonLanguage]);

  return (
    <div className="level-box-wrapper mb-3">
      <div className="container">
        <div className="row">
          {showSpinner ? (
            <div className="col-12 text-center">
              <Loader type="Oval" color="#85d8ce" height={158} width={70} />
            </div>
          ) : (
            <div className="col-12">
              <h5 className="text-center mb-3">
                {lessonCompleteCount}/{getLanguageLessonsCount(lessonLanguage)}{" "}
                Lessons Complete
              </h5>
              <div className="user-course-score mb-3">{userScore}</div>
              <h5 className="text-center">
                {lessonLanguage.toUpperCase()} Level
              </h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LevelBox;
