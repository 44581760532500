import React from "react";
import { Tabs, Tab } from "react-bootstrap-tabs";
import UserInfoTab from "./components/UserInfoTab.jsx";
import AccountSettingsTab from "./components/AccountSettingsTab.jsx";
import "./index.scss";

const SettingsPage = () => {
  return (
    <div className="container settings-page-wrapper">
      <div className="row">
        <div className="col-12">
          <div className="settings-tabs shadow">
            <Tabs>
              <Tab label="Profile">
                <UserInfoTab />
              </Tab>
              <Tab label="Account">
                <AccountSettingsTab />
              </Tab>
              {/* <Tab label="Password"><PasswordTab /></Tab> */}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
