import React, { useState } from "react";
import Tabs from "./components/Tabs";
import FriendsTab from "./components/FriendsTab";
import RequestsTab from "./components/RequestsTab";
import GlobalAlertsTab from "./components/GlobalAlertsTab";
import { AlertProvider } from "../../hooks/alert.hooks";
import { useAuth } from "../../hooks/auth.hooks";
import { TabContent, TabPane } from "reactstrap";
import "./index.scss";

const AlertsWidget = () => {
  const auth = useAuth();
  const [activeTab, setActiveTab] = useState("1");
  const [isListMinimized, setIsListMinimized] = useState(true);
  const [requestCount, setRequestCount] = useState(0);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleMinimize = () => {
    setIsListMinimized(!isListMinimized);
  };

  const requestListCount = requestCount => {
    setRequestCount(requestCount);
  };

  if (auth && auth.user && !auth.user.isAnonymous) {
    return (
      <AlertProvider>
        <div
          className="chat-box-wrapper shadow"
          style={isListMinimized ? { bottom: -358 } : { bottom: 0 }}
        >
          <Tabs
            toggle={toggle}
            activeTab={activeTab}
            toggleMinimize={toggleMinimize}
            isListMinimized={isListMinimized}
            requestCount={requestCount}
          />

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <FriendsTab />
            </TabPane>

            <TabPane tabId="2">
              <RequestsTab requestListCount={requestListCount} />
            </TabPane>

            <TabPane tabId="3">
              <GlobalAlertsTab />
            </TabPane>
          </TabContent>
        </div>
      </AlertProvider>
    );
  } else {
    return null;
  }
};

export default AlertsWidget;
