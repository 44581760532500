import React, { useState } from "react";
import { useAuth } from "../../hooks/auth.hooks";
import { useLocation } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler } from "reactstrap";
import { Link } from "react-router-dom";
import NavLinks from "./components/NavLinks";
import NavSearch from "./components/NavSearch";
import "./index.scss";

const NavigationBar = () => {
  const auth = useAuth();
  const [pathname, setPathname] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  let location = useLocation();
  React.useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  return (
    <Navbar
      light
      expand="md"
      className={pathname !== "/" ? "navy-to-teal-bg" : null}
    >
      <Link className="navbar-brand" to="/">
        <img
          alt="logo"
          className="logo"
          style={{ maxWidth: 200 }}
          src={
            pathname !== "/"
              ? require("../../assets/logos/coduo-logo-white.png")
              : require("../../assets/logos/coduo-logo-black.png")
          }
        />
      </Link>
      {auth.user && !auth.user.isAnonymous ? <NavSearch /> : null}
      <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
      <Collapse isOpen={isOpen} navbar>
        <NavLinks user={auth.user} currentUrlPath={pathname} />
      </Collapse>
    </Navbar>
  );
};

export default NavigationBar;
