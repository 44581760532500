import React from "react";
import "./index.scss";

const CoursesCard = ({
  lessonData: { language, numberOfLessons, description }
}) => {
  return (
    <div className="course-card-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <h4>{language.toUpperCase()}</h4>
          </div>
          <div className="col-12 col-lg-6 text-md-right">
            <h6>
              {numberOfLessons} Lessons | {0.25 * numberOfLessons} Hours
            </h6>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {description ? (
              <p>{description}</p>
            ) : (
              <p className="text-center">Coming Soon!</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursesCard;
