import React, { useState, useEffect, useContext, createContext } from "react";
import { TOAST_LEVEL, LESSON_TYPES } from "../constants";
import firebase from "../config/firebase";
import { fireToast } from "../helpers";
import { useAuth } from "./auth.hooks";
import {
  getChatRef,
  createMessageObject,
  getLessonData,
  getParams
} from "../helpers";
import { useHistory } from "react-router-dom";
const uuidv4 = require("uuid/v4");

// Contexts
const EditorContext = createContext();

// Providers
export const EditorProvider = ({ children }) => {
  const editor = useProvideEditor();
  return (
    <EditorContext.Provider value={editor}>{children}</EditorContext.Provider>
  );
};

// Hook thats exposed
export const useEditor = () => {
  return useContext(EditorContext);
};

const useProvideEditor = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [roomId, setRoomId] = useState("");
  const [roomRef, setRoomRef] = useState("");
  const [isRoomPrivate, setIsRoomPrivate] = useState(false);
  const [isForLesson, setIsForLesson] = useState(false);
  const [currentUserIsRoomOwner, setCurrentUserIsRoomOwner] = useState(false);
  const [isNewEditorSession, setIsNewEditorSession] = useState(false);
  const [currentLesson, setCurrentLesson] = useState([]);
  const [
    currentLessonVerificationData,
    setCurrentLessonVerificationData
  ] = useState({});
  const history = useHistory();
  const auth = useAuth();

  useEffect(() => {
    if (roomId) setRoomRef(getRoomRef(roomId));
    if (roomRef) listenToRoomSettings();
    if (auth && auth.user && roomRef)
      setCurrentUserIsRoomOwner(setRoomOwnerId());
    if (isForLesson) setCurrentLessonData();

    // clean up listeners
    if (roomRef) return () => detachSettingsListener();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId, auth, isForLesson]);

  /**
   * Gets the ID of the room.
   *
   * @returns {string} Room ID.
   */
  const getRoomId = () => {
    const roomIdParam = getParams("room_id");
    // We set a flag to know if its a new room ID or not, used to redirect user inside the editor
    setIsNewEditorSession(!!!roomIdParam);
    // Also return the ID
    return roomIdParam ? roomIdParam : uuidv4();
  };

  /**
   * Gets the room type (lesson vs non-lesson).
   *
   * @returns {boolean} Whether room is for lesson or not
   */
  const getRoomType = () => {
    return !!getParams("language"); // if we have language param we can assume its for lesson and set flag to true
  };

  /**
   * Gets firebase reference for the room
   * based on the ID.
   *
   * @param {string} roomId The room ID.
   */
  const getRoomRef = roomId => {
    return firebase
      .database()
      .ref("editorRooms")
      .child(roomId);
  };

  /**
   * Sets isPrivate setting for room in Firebase.
   *
   * @param {boolean} value
   */
  const changeRoomReadWriteSettings = value => {
    if (!roomId) return;

    const settingsRef = firebase
      .database()
      .ref("editorRooms")
      .child(roomId)
      .child("settings");
    settingsRef.child("isPrivate").set(value);
  };

  /**
   * Attaches listener to room settings.
   */
  const listenToRoomSettings = () => {
    if (!roomId) return;

    const roomRef = firebase
      .database()
      .ref("editorRooms")
      .child(roomId);
    roomRef.child("settings").on("value", snapshot => {
      if (snapshot.val()) setIsRoomPrivate(snapshot.val().isPrivate);
    });
  };

  /**
   * Detaches room settings listener.
   */
  const detachSettingsListener = () => {
    roomRef.child("settings").off();
  };

  /**
   * Sets the current room owner ID.
   */
  const setRoomOwnerId = () => {
    if (!roomId) return;
    const ref = firebase
      .database()
      .ref("editorRooms")
      .child(roomId);
    // If no room owner exists we set current user as owner
    const ownerRef = ref.child("settings").child("owner");
    ownerRef.once("value", snapshot => {
      if (snapshot.val()) {
        if (auth.user.uid === snapshot.val()) setCurrentUserIsRoomOwner(true);
      } else {
        // No room owner found, set current user as owner
        ownerRef.set(auth.user.uid);
        setCurrentUserIsRoomOwner(true);
      }
    });
  };

  /**
   * Sets the current lesson data.
   */
  const setCurrentLessonData = (language, number, type) => {
    const lessonLanguage = getParams("language") || language;
    const lessonNumber = Number(getParams("lesson_number")) || Number(number);
    const lessonType = getParams("lesson_type") || type;

    const lessonData = getLessonData(lessonLanguage, lessonNumber);

    setCurrentLesson({ ...lessonData, lessonType });
  };

  /**
   * Submits a lesson based on the room ID provided.
   *
   * @param {string} language Language of lesson.
   * @param {number} lessonNumber Number of lesson.
   */
  const submitLesson = (language, lessonNumber) => {
    const roomRef = firebase
      .database()
      .ref("editorRooms")
      .child(roomId);
    roomRef.once("value", snapshot => {
      if (snapshot.val()) {
        const { html, css, js } = snapshot.val();
        const lessonVerificationData = verifyLesson(
          language,
          lessonNumber,
          html,
          css,
          js
        );
        return setCurrentLessonVerificationData(lessonVerificationData);
      }
    });
  };

  /**
   * Function that takes in videoOnly flag and lessonHistoryObj and sends
   * user to next lesson.
   *
   * @param {boolean} sendToLessonVideo Flag depicting whether next lesson will start with a lesson-video page intro.
   * @param {object} lessonData Object containing data for lesson.
   * @param {string} roomId Optional value of what the roomId should be. Used mainly for match making.
   */
  const sendToNextLesson = (sendToLessonVideo, lessonData, roomId) => {
    // If this lesson is video only type lesson, we send them to the next lessons intro video
    // Else we take them to a lesson
    const id = roomId || uuidv4();
    const lesson = { ...lessonData, id };
    if (sendToLessonVideo) {
      history.push("/lesson-video", lesson);
    } else {
      const { language, lessonNumber, lessonType } = lessonData;
      setCurrentLessonData(language, lessonNumber, lessonType);
      history.push(
        `/code-editor?room_id=${id}&language=${language}&lesson_number=${lessonNumber}&lesson_type=${lessonType}`
      );
    }
  };

  /**
   * The lesson verification data that contains details of why the user passed or failed.
   * @typedef {Object} VerificationData
   * @property {boolean} lessonPassed - Indicates whether lesson was passed or not.
   * @property {boolean[]} errors - Contains the boolean pass/fail values for each objective.
   */

  /**
   * Function that calls API Gateway/Lambda to verify lesson.
   *
   * @param {string} language Language of lesson being verified.
   * @param {number} lessonNumber Number of lesson being verified.
   * @param {string} html HTML content being verified.
   * @param {string} css CSS content being verified.
   * @param {string} js JS content being verified.
   *
   * @returns {VerificationData} The object containing verification data.
   */
  const verifyLesson = (language, lessonNumber, html, css, js) => {
    console.log("verifiy these", language, lessonNumber, html, css, js);
    // API Gateway accepts this:
    /**
     * {
     *  language: string;
     *  lesson_number: string;
     *  html: string;
     *  css: string;
     *  js: string;
     * }
     */

    // calls api gateway, does whatever it needs to do, returns:
    // { lessonPassed: boolean, errors?: [true, false, false, true] }
    return {
      lessonPassed: true,
      errors: [true, false, false, true, false]
    };
  };

  /**
   * Begins process of having user work with a friend.
   *
   */
  const handlePickAFriend = friendId => {
    if (!auth.user) return;
    setIsLoading(true);

    const userId = auth.user.uid;
    const chatRefId = getChatRef(friendId, userId);
    const chatRef = firebase
      .database()
      .ref("chats")
      .child(chatRefId);

    const input = `Hi there! ${auth.user.displayName} wants to do a lesson with you. Click the button below to accept.`;

    const message = createMessageObject(
      auth.user.displayName,
      input,
      userId,
      true,
      false,
      false,
      currentLesson,
      uuidv4()
    );

    const inviteMessageId = chatRef.push();

    inviteMessageId.set(message);

    // Attach listener to message ref, if friend accepted property changes
    // we redirect this user to the same room as them.
    // If it expires, we remove listener.
    chatRef.child(inviteMessageId.key).on("value", snapshot => {
      if (snapshot.val() && snapshot.val().inviteAccepted) {
        // send them to the same room
        const snap = snapshot.val();
        const lesson = snap.lessonData;
        sendToNextLesson(true, lesson, snap.id);
      }
    });

    setTimeout(() => {
      setIsLoading(false);
      chatRef.child(inviteMessageId.key).off();
      chatRef.child(inviteMessageId.key).update({
        inviteExpired: true
      });
    }, 30000);
  };

  const handleAcceptInvite = (key, uid, friendId, lessonType) => {
    if (uid === friendId) return;

    // For friend type lesson we need to check their conversation chat ref
    // to see if its expired (because we send the invite through the users
    // message). But for match_making type we check the globalAlerts ref.
    let ref;

    if (lessonType === LESSON_TYPES.MATCH_MAKING) {
      ref = firebase
        .database()
        .ref("globalAlerts")
        .child(key);
    }

    if (lessonType === LESSON_TYPES.WITH_A_FRIEND) {
      const chatRefId = getChatRef(friendId, uid);
      ref = firebase
        .database()
        .ref("chats")
        .child(chatRefId)
        .child(key);
    }

    // Check if invite has expired, disable and change text if it has
    // we store certain variables outside the function so we can redirect later
    let snapVal = "";
    ref.once("value", snapshot => {
      if (snapshot.val()) snapVal = snapshot.val();
    });

    if (snapVal && snapVal.inviteExpired) {
      fireToast("Sorry, this invite expired", TOAST_LEVEL.ERROR);
      return;
    }

    // First we update the inviteAccepted property in the db
    // so that the other user gets redirected as well
    ref.update({
      inviteAccepted: true
    });

    // Second we redirect this user
    if (snapVal) {
      const lesson = snapVal.lessonData;
      sendToNextLesson(true, lesson, snapVal.id);
    }
  };

  /**
   * Takes in lesson number and lesson language and updates the users
   * lessonCompletions node in the database. This node lets us update
   * the users score and show a green check next to the lesson completed.
   *
   * @param {string} lessonNumber The lesson number the user completed.
   * @param {string} lessonLanguage The lesson language the user completed.
   */
  const updateUsersLessonCompletionScore = (lessonNumber, lessonLanguage) => {
    const lessonCompletionsRef = firebase.database().ref("lessonCompletions");
    // Format the lesson number to match the "lessonName" property in the
    // course-data.js file i.e html-lesson-00.
    const formattedLessonNumber =
      lessonNumber < 10 ? `0${lessonNumber}` : lessonNumber.toString();
    const formattedLessonLanguage = lessonLanguage.toLowerCase();
    const lesson = `${formattedLessonLanguage}-lesson-${formattedLessonNumber}`;

    const uid = auth.user.uid;

    lessonCompletionsRef
      .child(uid)
      .child(formattedLessonLanguage)
      .update({
        [lesson]: true
      });
  };

  return {
    roomId,
    roomRef,
    isForLesson,
    getParams,
    changeRoomReadWriteSettings,
    isRoomPrivate,
    currentUserIsRoomOwner,
    isNewEditorSession,
    currentLesson,
    submitLesson,
    currentLessonVerificationData,
    sendToNextLesson,
    handlePickAFriend,
    getRoomId,
    setRoomId,
    setIsForLesson,
    getRoomType,
    setCurrentLessonData,
    isLoading,
    handleAcceptInvite,
    setRoomRef,
    updateUsersLessonCompletionScore
  };
};
