import React from "react";
import Loader from "react-loader-spinner";
import { BUTTON_CONSTANTS } from "../../constants";
import "./index.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Button = props => {
  let buttonStyle = "";
  switch (props.buttonStyle) {
    case BUTTON_CONSTANTS.GREY_TO_NAVY:
      buttonStyle = BUTTON_CONSTANTS.GREY_TO_NAVY;
      break;

    case BUTTON_CONSTANTS.WHITE_TO_GREY:
      buttonStyle = BUTTON_CONSTANTS.WHITE_TO_GREY;
      break;

    case BUTTON_CONSTANTS.MATCH_MAKING_CTA:
      buttonStyle = BUTTON_CONSTANTS.MATCH_MAKING_CTA;
      break;

    default:
      break;
  }
  return (
    <p className="text-center mb-0">
      <button
        style={props.styleProps}
        onClick={props.onClick}
        type="submit"
        className={`btn ${buttonStyle}`}
        disabled={props.isLoading}
      >
        {props.isLoading ? (
          <Loader type="Oval" color="#85d8ce" height={20} width={50} />
        ) : (
          props.buttonText
        )}
      </button>
    </p>
  );
};

export default Button;
