import React from "react";
import Button from "../Button";
import { BUTTON_CONSTANTS } from "../../constants";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Shape of data props that are received:
//
// {
//   "exhaustiveNbHits": true,
//   "hits": [
//     {
//       "_highlightResult": {
//         "username": {
//           "fullyHighlighted": false,
//           "matchLevel": "full",
//           "matchedWords": [
//             "sam"
//           ],
//           "value": "<em>sam</em>sams"
//         }
//       },
//       "objectID": "tcmiEpUEbHh4oI2NR3ftxY8pUgC3",
//       "username": "samsams"
//     }
//   ],
//   "hitsPerPage": 20,
//   "nbHits": 1,
//   "nbPages": 1,
//   "page": 0,
//   "params": "query=sam",
//   "processingTimeMS": 1,
//   "query": "sam"
// }

const LockedEditorModal = props => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Not Permitted To Write</ModalHeader>
      <ModalBody>
        <p>
          Sorry, looks like this editor is locked. Please create your own
          editor.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
          buttonText={"Cancel"}
          onClick={props.toggle}
        />
      </ModalFooter>
    </Modal>
  );
};

export default LockedEditorModal;
