import React, { useState } from "react";
import { useAuth } from "../../../../hooks/auth.hooks";
import { createMessageObject } from "../../../../helpers";
import "./index.scss";

const ChatInput = ({ chatRef }) => {
  const auth = useAuth();
  const [input, setInput] = useState("");

  const handleEnterPress = e => {
    if (e.key === "Enter" && input && auth && auth.user) {
      const message = createMessageObject(
        auth.user.displayName,
        input,
        auth.user.uid,
        false,
        false,
        false,
        [],
        ""
      );
      chatRef.push(message);
      setInput("");
    }
  };

  const handleInputChange = e => {
    setInput(e.target.value);
  };

  return (
    <input
      className="chat-input shadow"
      placeholder="Type your message..."
      onKeyDown={handleEnterPress}
      value={input}
      type="text"
      onChange={handleInputChange}
    />
  );
};

export default ChatInput;
