import React from "react";
import "./index.scss";

/**
 * Takes in a message object and styles it.
 *
 * @param {String} username username of message sender
 * @param {String} text message sent by user
 * @param {String} createdAt string date time of when message was sent
 * @param {String} userId user ID of message sender
 * @param {String} currentUserId user ID of the current logged in user
 */
const ChatBubble = ({ username, text, createdAt, userId, currentUserId }) => {
  return (
    <div
      className={`${
        userId === currentUserId ? "bubble1" : "bubble2"
      } p-2 m-0 position-relative`}
      data-is={`${username ? username : "Anonymous"} - ${createdAt}`}
    >
      <div
        className={`${userId === currentUserId ? "float-right" : "float-left"}`}
      >
        {text}
      </div>
    </div>
  );
};

export default ChatBubble;
