import React, { useState, useEffect, useContext, createContext } from "react";
import firebase from "../config/firebase";
import { loggerService } from "../helpers";
import { LOG_TYPES } from "../constants";

// Contexts
const AuthContext = createContext();

// Providers
export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

// Hook thats exposed
export const useAuth = () => {
  return useContext(AuthContext);
};

const useProvideAuth = () => {
  const [user, setUser] = useState(null);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signIn = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(response => {
        setUser(response.user);
        return response.user;
      });
  };

  const reAuthenticateUser = (email, password) => {
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        loggerService("User re-authenticated...", null, LOG_TYPES.LOG);
      })
      .catch(error => {
        loggerService("Error when deleting...", error, LOG_TYPES.ERROR);
      });
  };

  /**
   * Since all of our users are anon users by default
   * we do not use the regular createUserWithEmailAndPassword
   * method. Instead we must convert anon to new user.
   */
  // const signUp = (email, password, username) => {
  //   return firebase
  //     .auth()
  //     .createUserWithEmailAndPassword(email, password)
  //     .then(response => {
  //       updateAlgoliaSearchIndex(response.user, username);
  //       updateProfileUsername(response.user, username);
  //       updateUserInfoNode(response.user, username);
  //       setUser(response.user);
  //       return response.user;
  //     });
  // };

  /**
   * Sign up method that upgrades user
   * from anonymous to full user.
   *
   * @param {string} email
   * @param {string} password
   * @param {string} username
   */
  const signUp = (email, password, username) => {
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    return firebase
      .auth()
      .currentUser.linkWithCredential(credential)
      .then(
        userCred => {
          const { user } = userCred;
          updateAlgoliaSearchIndex(user, username);
          updateProfileUsername(user, username);
          updateUserInfoNode(user, username);
          setUser(user);
          loggerService(
            "Anonymous account successfully upgraded...",
            null,
            LOG_TYPES.LOG
          );
          return user;
        },
        error => {
          loggerService(
            "Error upgrading anonymous account...",
            error,
            LOG_TYPES.ERROR
          );
        }
      );
  };

  const signOut = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
      });
  };

  const sendPasswordResetEmail = email => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  const updateAlgoliaSearchIndex = (user, username) => {
    if (user && username) {
      const rootRef = firebase.database().ref("algolia_user_index");
      const usernameRef = rootRef.child(user.uid);
      usernameRef.update({
        username,
        userId: user.uid
      });
    }
  };

  const updateProfileUsername = (user, username) => {
    if (user && username) {
      user.updateProfile({
        displayName: username
      });
    }
  };

  const updateUserInfoNode = (user, username) => {
    if (user && username) {
      const rootRef = firebase
        .database()
        .ref("userInfo")
        .child(user.uid);
      rootRef.child("username").set(username);
    }
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      } else {
        // We want all non-signed in users to be anonymousUsers,
        // so if user does not exist, sign them up as anon user
        // which triggers onAuthStateChanged
        firebase
          .auth()
          .signInAnonymously()
          .catch(error => {
            loggerService(
              "Error when signing in anonymously...",
              error,
              LOG_TYPES.ERROR
            );
          });
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    signIn,
    signUp,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
    reAuthenticateUser,
    updateProfileUsername
  };
};
