import React from "react";
import { useForm } from "../../../hooks/form.hooks";
import Button from "../../../components/Button";
import { FORM_TYPES, BUTTON_CONSTANTS } from "../../../constants";

const AccountSettingsTab = () => {
  const {
    inputs,
    handleInputChange,
    handleSubmit,
    isLoading,
    isSuccess,
    isFailure,
    failuredMessage
  } = useForm(FORM_TYPES.DELETE_ACCOUNT_FORM);

  return (
    <div className="text-center">
      <h4 className="text-danger mt-2 mb-4">Delete account</h4>
      <h4 className="text-danger mb-4">
        <strong>WARNING: THIS IS PERMANENT</strong>
      </h4>
      <p className="text-danger">
        Please enter your email and password to delete your account.
      </p>
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <div className="input-group mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                aria-label="Email"
                name="email"
                onChange={handleInputChange}
                value={inputs.email}
              />
            </div>

            <div className="input-group mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                aria-label="Password"
                name="password"
                onChange={handleInputChange}
                value={inputs.password}
              />
            </div>
          </div>
        </div>
      </div>
      <Button
        styleProps={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
        buttonText={"Delete Account"}
        onClick={handleSubmit}
        isLoading={isLoading}
      />

      {isSuccess ? (
        <div className="alert alert-success mt-5" role="alert">
          Successfully updated username!
        </div>
      ) : null}

      {isFailure ? (
        <div className="alert alert-danger mt-5" role="alert">
          {failuredMessage
            ? failuredMessage
            : "Something went wrong... Please try again."}
        </div>
      ) : null}
    </div>
  );
};

export default AccountSettingsTab;
