import React from "react";
import LoginForm from "../../components/LoginForm";
import "./index.scss";

const LoginPage = () => {
  return (
    <div className="login-page-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
