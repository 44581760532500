import React, { useState, useEffect } from "react";
import firebase from "../../config/firebase";
import Loader from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import { useList } from "react-firebase-hooks/database";
import { useAuth } from "../../hooks/auth.hooks";
import ChatContainer from "../../components/ChatContainer";
import ChatInput from "../../components/ChatContainer/components/ChatInput";
import Button from "../../components/Button";
import { getNextLessonData } from "../../helpers";
import { useEditor } from "../../hooks/editor.hooks";
import { BUTTON_CONSTANTS, LESSON_TYPES } from "../../constants";
import YouTube from "react-youtube";
import "./index.scss";
const uuidv4 = require("uuid/v4");

const LessonVideoPage = () => {
  const location = useLocation();

  const auth = useAuth();
  const [continueButtonText, setContinueButtonText] = useState(
    "Continue to Lesson"
  );
  const lessonVideoChatId =
    location.state && location.state.id ? location.state.id : "default";
  const videoLessonChatRef = firebase
    .database()
    .ref("lessonVideoChats")
    .child(lessonVideoChatId);
  const [value] = useList(videoLessonChatRef);
  const [isLoading, setIsLoading] = useState(false);
  const { sendToNextLesson } = useEditor();

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [location]);

  const youtubeOptions = {
    height: "410",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1
    }
  };

  const handleContinueToNextLessonClick = () => {
    if (!auth && !auth.user) return;

    // If the lesson is not solo, we must wait for both people
    // to accept the invite before sending them to the next step.
    if (location.state.lessonType !== LESSON_TYPES.SOLO && location.state.id) {
      // 1. Set continue true for user 1
      const ref = firebase
        .database()
        .ref("lessonVideoUpdates")
        .child(location.state.id);
      const continueToLessonRef = ref.child("continueToLesson");

      continueToLessonRef.update({
        [auth.user.uid]: true
      });

      setContinueButtonText("Waiting for friend to click continue");

      // 2. We need to add the next room ID to firebase so both users have access to it
      // and can navigate to the same room. For this reason, the first user to submit
      // also sets the next lessons room ID if it doesnt exist.
      const nextLessonIdRef = ref.child("nextLessonId");
      nextLessonIdRef.once("value", snapshot => {
        if (!snapshot.val()) {
          const id = uuidv4();
          nextLessonIdRef.set(id);
        }
      });

      // 3. Listen for if both users are accepted
      continueToLessonRef.on("value", snapshot => {
        // We check if two users are in the snapshot, if true it means they've
        // both accepted to go forward.
        if (snapshot.val() && Object.keys(snapshot.val()).length === 2) {
          nextLessonIdRef.once("value", snapshot => {
            if (snapshot.val()) {
              continueToLesson(snapshot.val());
            }
          });
        }
      });
    } else {
      continueToLesson(); // solo lessons dont need pre-defined next lesson id's, leave it empty
    }
  };

  const continueToLesson = nextLessonId => {
    // If video only, we send them to next lesson.
    // Else, we send them to the current lessons code-editor page.
    const nextLessonData = getNextLessonData(
      location.state.lessonNumber,
      location.state.language,
      location.state.lessonType
    );

    console.log(
      "the next lesson data",
      location.state.videoOnly,
      nextLessonData,
      nextLessonId
    );

    if (nextLessonId) {
      sendToNextLesson(location.state.videoOnly, nextLessonData, nextLessonId);
    } else {
      sendToNextLesson(location.state.videoOnly, nextLessonData);
    }
  };

  console.log("Location: ", location.state);

  return (
    <>
      {isLoading ? (
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12" style={{ marginTop: 200 }}>
              <Loader
                type="Oval"
                color="#10577d"
                height={100}
                width={100}
                timeout={3000}
                className="text-center"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="lesson-video-page-wrapper">
          {!location.state || !location.state.videoId ? (
            <div>
              Sorry, please access the page through a lesson (link to lessons)
            </div>
          ) : (
            <div className="container">
              <div className="row mt-5">
                <div className="col-12">
                  <h2 className="navy">{location.state.lessonTitleLong}</h2>
                  <h4 className="navy">{location.state.lessonDescription}</h4>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-8 pr-0">
                  {location.state.videoId ? (
                    <YouTube
                      videoId={location.state.videoId}
                      className="embed-responsive embed-responsive-16by9"
                      opts={youtubeOptions}
                    />
                  ) : null}
                </div>
                <div className="col-lg-4 chat-container pt-3">
                  <ChatContainer messages={value} />
                  <ChatInput chatRef={videoLessonChatRef} />
                  <Button
                    styleProps={{ marginBottom: 20, marginTop: 20 }}
                    buttonStyle={BUTTON_CONSTANTS.MATCH_MAKING_CTA}
                    buttonText={continueButtonText}
                    onClick={handleContinueToNextLessonClick}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default LessonVideoPage;
