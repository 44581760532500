import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import { Collapse } from "reactstrap";
import SideMenu from "../../../../components/SideMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import firebase from "../../../../config/firebase";
import {
  getLessonData,
  getParams,
  getNextLessonData
} from "../../../../helpers";
import { useAuth } from "../../../../hooks/auth.hooks";
import { useEditor } from "../../../../hooks/editor.hooks";
import Button from "../../../../components/Button";
import { BUTTON_CONSTANTS, LESSON_TYPES } from "../../../../constants";
const uuidv4 = require("uuid/v4");

const LessonsPanel = ({ open, onClick }) => {
  const auth = useAuth();
  const [objectivesCollapse, setObjectivesCollapse] = useState(true);
  const [videoCollapse, setVideoCollapse] = useState(false);
  const [descriptionCollapse, setDescriptionCollapse] = useState(false);
  const [transcriptCollapse, setTranscriptCollapse] = useState(false);
  const [solutionCollapse, setSolutionCollapse] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState("Submit Lesson");
  const [lessonData, setLessonData] = useState([]);
  const {
    submitLesson,
    currentLessonVerificationData,
    roomRef,
    sendToNextLesson,
    updateUsersLessonCompletionScore
  } = useEditor();

  useEffect(() => {
    const lessonLanguage = getParams("language");
    const lessonNumber = Number(getParams("lesson_number"));

    if (lessonLanguage && lessonNumber)
      setLessonData(getLessonData(lessonLanguage, lessonNumber));

    if (
      currentLessonVerificationData &&
      Object.keys(currentLessonVerificationData).length &&
      currentLessonVerificationData.lessonPassed
    ) {
      const lessonNumber = getParams("lesson_number");
      const lessonLanguage = getParams("language");
      const lessonType = getParams("lesson_type");

      const nextLessonData = getNextLessonData(
        lessonNumber,
        lessonLanguage,
        lessonType
      );

      // If its solo we send them to a new room.
      if (lessonType === LESSON_TYPES.SOLO) {
        updateUsersLessonCompletionScore(lessonNumber, lessonLanguage);
        sendToNextLesson(true, nextLessonData);
        return;
      }

      // If its not solo type we need to get the next room id
      // so we can send both users to the same place.
      roomRef.child("nextLessonId").once("value", snapshot => {
        if (snapshot.val()) {
          const nextRoomId = snapshot.val();
          updateUsersLessonCompletionScore(lessonNumber, lessonLanguage);
          sendToNextLesson(true, nextLessonData, nextRoomId);
          return;
        }
      });
    }
  }, [
    currentLessonVerificationData,
    lessonData,
    roomRef,
    sendToNextLesson,
    updateUsersLessonCompletionScore
  ]);

  const handleSubmitLesson = (language, lessonNumber) => {
    if (!auth && !auth.user) return;

    // If lesson is solo, submit lesson
    // Else, we must wait for both users to submit

    if (getParams("lesson_type") === LESSON_TYPES.SOLO) {
      submitLesson(language, lessonNumber);
    } else {
      const ref = firebase.database().ref("lessonSubmissions");
      const lessonSubmissionRef = ref.child(getParams("room_id"));

      // Set submission to true for user 1
      lessonSubmissionRef.update({
        [auth.user.uid]: true
      });

      setSubmitButtonText("Waiting for partner to submit lesson");

      // When the first user hits submit, we set the next room ID
      // to be used by for the next lesson.
      const nextLessonIdRef = roomRef.child("nextLessonId");
      nextLessonIdRef.once("value", snapshot => {
        if (!snapshot.val()) {
          const id = uuidv4();
          nextLessonIdRef.set(id);
        }
      });

      // We listen for changes to the ref and send if count is 2
      lessonSubmissionRef.on("value", snapshot => {
        if (snapshot.val() && Object.keys(snapshot.val()).length === 2) {
          submitLesson(language, lessonNumber);
        }
      });
    }
  };

  return (
    <SideMenu open={open} onClick={onClick}>
      <div className="container navy">
        <div className="row mb-2">
          <div className="col-12">
            <h3>Lesson {lessonData ? lessonData.lessonTitleLong : null}</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h5
              className="text-center pointer"
              onClick={() => setObjectivesCollapse(!objectivesCollapse)}
            >
              Objectives
              <FontAwesomeIcon
                className="ml-2"
                icon={objectivesCollapse ? faChevronDown : faChevronRight}
              />
            </h5>
            <Collapse isOpen={objectivesCollapse}>
              <ul>
                {lessonData && lessonData.lessonObjectives
                  ? lessonData.lessonObjectives.map((objective, i) => {
                      // const objectivePassed = lessonDataVerificationData.errors && lessonDataVerificationData.errors.length ? lessonDataVerificationData.errors[i] : null;
                      // let color = "";
                      // if (objectivePassed !== null) {
                      //   color = objectivePassed ? "#85d8ce" : "red";
                      // }
                      // return <li style={{ marginBottom: 10, color }} key={i}>{objective}</li>
                      return (
                        <li style={{ marginBottom: 10 }} key={i}>
                          {objective}
                        </li>
                      );
                    })
                  : null}
              </ul>
            </Collapse>
            <Button
              buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
              buttonText={submitButtonText}
              onClick={() =>
                handleSubmitLesson(lessonData.language, lessonData.lessonNumber)
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <hr />
            <h5
              className="text-center pointer"
              onClick={() => setVideoCollapse(!videoCollapse)}
            >
              Video
              <FontAwesomeIcon
                className="ml-2"
                icon={videoCollapse ? faChevronDown : faChevronRight}
              />
            </h5>
            <Collapse isOpen={videoCollapse}>
              <YouTube
                videoId={lessonData ? lessonData.videoId : null}
                className="embed-responsive embed-responsive-16by9"
              />
            </Collapse>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <hr />
            <h5
              className="text-center pointer"
              onClick={() => setDescriptionCollapse(!descriptionCollapse)}
            >
              Description
              <FontAwesomeIcon
                className="ml-2"
                icon={descriptionCollapse ? faChevronDown : faChevronRight}
              />
            </h5>
            <Collapse isOpen={descriptionCollapse}>
              {lessonData ? lessonData.lessonDescription : null}
            </Collapse>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <hr />
            <h5
              className="text-center pointer"
              onClick={() => setTranscriptCollapse(!transcriptCollapse)}
            >
              Transcript
              <FontAwesomeIcon
                className="ml-2"
                icon={transcriptCollapse ? faChevronDown : faChevronRight}
              />
            </h5>
            <Collapse isOpen={transcriptCollapse}>
              {lessonData && lessonData.lessonTranscript
                ? lessonData.lessonTranscript.map((transcript, i) =>
                    transcript.code ? (
                      <pre
                        key={i}
                        style={{ backgroundColor: "#e9ecef", padding: 5 }}
                      >
                        {transcript.content}
                      </pre>
                    ) : (
                      <p key={i}>{transcript}</p>
                    )
                  )
                : null}
            </Collapse>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-12">
            <hr />
            <h5
              className="text-center pointer"
              onClick={() => setSolutionCollapse(!solutionCollapse)}
            >
              Solution
              <FontAwesomeIcon
                className="ml-2"
                icon={solutionCollapse ? faChevronDown : faChevronRight}
              />
            </h5>
            <Collapse isOpen={solutionCollapse}>
              {lessonData && lessonData.lessonSolution
                ? lessonData.lessonSolution.map((solution, i) =>
                    solution.code ? (
                      <pre
                        key={i}
                        style={{ backgroundColor: "#e9ecef", padding: 5 }}
                      >
                        {solution.content}
                      </pre>
                    ) : (
                      <p key={i}>{solution}</p>
                    )
                  )
                : null}
            </Collapse>
          </div>
        </div>
      </div>
    </SideMenu>
  );
};

export default LessonsPanel;
