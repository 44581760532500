import React from "react";
import { useHistory } from "react-router-dom";
import { coursesAvailable } from "../../config/course-data";
import CoursesCard from "./components/CoursesCard";
import "./index.scss";

const CoursesPage = () => {
  const history = useHistory();

  const handleCourseClick = (language, courseEnabled) => {
    if (courseEnabled) history.push(`/courses/${language}`);
  };

  return (
    <div className="courses-page-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Coduo Courses</h2>
            <p>
              Coduo courses put a unique spin on the traditional learn-by-doing
              method.
            </p>
            <p>
              Coduo allows you to work with a friend, or be paired with another
              student to tackle challenging lessons together. You can also
              complete lessons with friends or alone.
            </p>
          </div>
        </div>
        <div className="row">
          {coursesAvailable.map(data => (
            <div
              key={data.language}
              className="col-12 col-lg-6"
              onClick={() =>
                handleCourseClick(data.language, data.courseEnabled)
              }
            >
              <CoursesCard lessonData={data} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CoursesPage;
