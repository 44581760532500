import React from "react";
import { useAuth } from "../../hooks/auth.hooks";
import firebase from "../../config/firebase";
import FileUploader from "react-firebase-file-uploader";
import { LOG_TYPES } from "../../constants";
import { loggerService } from "../../helpers/index";
import "./index.scss";

const ImageUploader = props => {
  const auth = useAuth();

  const uid = auth.user ? auth.user.uid : "uid_unknown";

  const handleUploadStart = () => {
    loggerService("Profile image upload starting...", null, LOG_TYPES.LOG);
  };

  const handleUploadError = () => {
    loggerService("Error when uploading...", null, LOG_TYPES.ERROR);
  };

  const handleProgress = () => {
    loggerService(
      "Profile image upload progress update...",
      null,
      LOG_TYPES.LOG
    );
  };

  const handleUploadSuccess = filename => {
    firebase
      .storage()
      .ref(`profile_pictures/${uid}`)
      .child(filename)
      .getDownloadURL()
      .then(url => {
        updateUserProfile(url);
        storeFileName(url);
      });
  };

  const updateUserProfile = url => {
    auth.user
      .updateProfile({ photoURL: url })
      .then(() => {
        // Send alert to parent component via props
        // to update URL
        props.updatePhotoUrl(url);
      })
      .catch(error => {
        console.error("Error when updating profile URL: ", error);
      });
  };

  const storeFileName = url => {
    const rootRef = firebase
      .database()
      .ref("userInfo")
      .child(uid)
      .child("profileImageUrl");
    rootRef.set(url);
  };

  return (
    <div className="input-group mb-3 image-uploader-wrapper">
      <div className="custom-file">
        <FileUploader
          className="custom-file-input "
          accept="image/*"
          name="avatar"
          filename={uid}
          storageRef={firebase.storage().ref(`profile_pictures/${uid}`)}
          onUploadStart={handleUploadStart}
          onUploadError={handleUploadError}
          onUploadSuccess={handleUploadSuccess}
          onProgress={handleProgress}
        />
        <label className="custom-file-label">Choose file</label>
      </div>
    </div>
  );
};

export default ImageUploader;
