import React from "react";
import Button from "../Button";
import { BUTTON_CONSTANTS } from "../../constants";
import { useAuth } from "../../hooks/auth.hooks";
import { useEditor } from "../../hooks/editor.hooks";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import FriendsList from "./components/FriendsList";
import LoadingFriendInvite from "./components/LoadingFriendInvite";

const InviteFriendModal = ({ isOpen, toggle }) => {
  const auth = useAuth();
  const { isLoading } = useEditor();

  return (
    <Modal className="invite-friend-modal" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Invite a friend</ModalHeader>
      <ModalBody>
        {auth.user && auth.user.uid && !isLoading ? (
          <FriendsList uid={auth.user.uid} />
        ) : (
          <LoadingFriendInvite />
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
          buttonText={"Cancel"}
          onClick={toggle}
        />
      </ModalFooter>
    </Modal>
  );
};

export default InviteFriendModal;
