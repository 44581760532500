import React from "react";
import { toast } from "react-toastify";
import { AuthProvider } from "./hooks/auth.hooks";
import { EditorProvider } from "./hooks/editor.hooks";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import HomePage from "./pages/HomePage";
import SettingsPage from "./pages/SettingsPage";
import CodeEditorPage from "./pages/CodeEditorPage";
import CoursesPage from "./pages/CoursesPage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import LanguageLessonPage from "./pages/LanguageLessonPage";
import BugPage from "./pages/BugPage";
import AlertsWidget from "./components/AlertsWidget";
import LessonVideoPage from "./pages/LessonVideoPage";
import TestPage from "./pages/TestPage";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 8000
});

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <EditorProvider>
          <NavigationBar />
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/settings">
              <SettingsPage />
            </Route>
            <Route path="/code-editor">
              <CodeEditorPage />
            </Route>
            <Route path="/courses/(html|css)">
              <LanguageLessonPage />
            </Route>
            <Route path="/courses">
              <CoursesPage />
            </Route>
            <Route path="/signup">
              <SignupPage />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/report-bug">
              <BugPage />
            </Route>
            <Route path="/lesson-video">
              <LessonVideoPage />
            </Route>
            <Route path="/test">
              <TestPage />
            </Route>
            <Route path="*">
              <p>404</p>
            </Route>
          </Switch>
          <AlertsWidget />
        </EditorProvider>
      </Router>
    </AuthProvider>
  );
};

export default App;
