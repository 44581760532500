import React, { useEffect } from "react";
import ListItem from "./ListItem";
import { useList } from "react-firebase-hooks/database";
import { useAuth } from "../../../../hooks/auth.hooks";
import firebase from "../../../../config/firebase";

const RequestsTab = ({ requestListCount }) => {
  const auth = useAuth();
  const [value, loading, error] = useList(
    firebase
      .database()
      .ref("pendingFriendRequests")
      .child(auth.user.uid)
      .child("incoming")
  );

  // We use useEffect to listen for
  // the value change of the requests
  // node and pass it back to the parent
  // so that we can render a count icon
  useEffect(() => {
    requestListCount(value.length);
  }, [value, requestListCount]);

  return (
    <>
      {error && <strong>Error: {error}</strong>}
      {loading && <span className="text-center">Loading...</span>}
      {!loading && !value.length && (
        <p className="text-center mt-5">No friend requests yet</p>
      )}
      {!loading &&
        value &&
        value.map(item => (
          <div key={item.key} className="container request-item-wrapper">
            <ListItem userId={item.val()} />
          </div>
        ))}
    </>
  );
};

export default RequestsTab;
