import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../hooks/auth.hooks";
import firebase from "../../../config/firebase";

const LessonCompletionIcon = ({ lessonName, lessonLanguage }) => {
  const auth = useAuth();
  const [lessonPassed, setLessonPassed] = useState(false);

  useEffect(() => {
    if (!auth || !auth.user) return;

    const rootRef = firebase.database().ref("lessonCompletions");
    const lessonCompletionsRef = rootRef
      .child(auth.user.uid)
      .child(lessonLanguage)
      .child(lessonName);

    lessonCompletionsRef.once("value", snapshot => {
      if (snapshot.val()) setLessonPassed(true);
    });
  }, [auth, lessonLanguage, lessonName]);

  return (
    <>
      {lessonPassed ? (
        <FontAwesomeIcon size={"3x"} icon={faCheckCircle} className="green" />
      ) : (
        <FontAwesomeIcon size={"3x"} icon={faTimesCircle} className="red" />
      )}
    </>
  );
};

export default LessonCompletionIcon;
