import React, { useEffect } from "react";
import { useList } from "react-firebase-hooks/database";
import ChatContainer from "../../../ChatContainer";
import firebase from "../../../../config/firebase";
import { useAlert } from "../../../../hooks/alert.hooks";

const GlobalAlertsTab = () => {
  const alerts = useAlert();
  const [value, loading, error] = useList(
    firebase.database().ref("globalAlerts")
  );

  useEffect(() => {
    alerts.setUnreadGlobalAlert(true);
  }, [value]);

  return (
    <>
      {error && <strong>Error: {error}</strong>}
      {loading && <span className="text-center">Loading...</span>}
      {!loading && !value.length && (
        <p className="text-center mt-5">No lesson invites yet</p>
      )}
      {!loading && value && <ChatContainer height={"100%"} messages={value} />}
    </>
  );
};

export default GlobalAlertsTab;
