import React, { useState } from "react";
import Button from "../Button";
import { BUTTON_CONSTANTS, LESSON_TYPES } from "../../constants";
import { generateLessonHistoryObject } from "../../helpers";
import { useHistory } from "react-router-dom";
import { useEditor } from "../../hooks/editor.hooks";
import InviteFriendModal from "../InviteFriendModal";
import FindingMatchModal from "../FindingMatchModal";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./index.scss";
const uuidv4 = require("uuid/v4");

const MatchMakingModal = ({
  lesson: {
    videoId,
    language,
    lessonNumber,
    lessonDescription,
    lessonTitleLong,
    videoOnly
  },
  isOpen,
  toggle
}) => {
  const history = useHistory();
  const { setCurrentLessonData } = useEditor();
  const [inviteFriendModalOpen, setInviteFriendModalOpen] = useState(false);
  const [findingMatchModalOpen, setFindingMatchModalOpen] = useState(false);

  return (
    <>
      <Modal className="login-modal-wrapper" isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          How would you like to do this lesson?
        </ModalHeader>
        <ModalBody>
          <Button
            styleProps={{ marginBottom: 10 }}
            buttonStyle={BUTTON_CONSTANTS.MATCH_MAKING_CTA}
            buttonText={"Solo"}
            onClick={() => {
              const lessonHistoryObj = generateLessonHistoryObject({
                videoId,
                language,
                lessonNumber,
                lessonDescription,
                lessonTitleLong,
                lessonType: LESSON_TYPES.SOLO,
                videoOnly,
                id: uuidv4()
              });

              history.push("/lesson-video", lessonHistoryObj);
            }}
          />
          <Button
            styleProps={{ marginBottom: 10 }}
            buttonStyle={BUTTON_CONSTANTS.MATCH_MAKING_CTA}
            buttonText={"Match with a friend"}
            onClick={() => {
              setCurrentLessonData(
                language,
                lessonNumber,
                LESSON_TYPES.WITH_A_FRIEND
              );
              setInviteFriendModalOpen(true);
            }}
          />
          <Button
            buttonStyle={BUTTON_CONSTANTS.MATCH_MAKING_CTA}
            buttonText={"Be matched with a partner"}
            onClick={() => {
              setCurrentLessonData(
                language,
                lessonNumber,
                LESSON_TYPES.MATCH_MAKING
              );
              setFindingMatchModalOpen(true);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
            buttonText={"Cancel"}
            onClick={toggle}
          />
        </ModalFooter>
      </Modal>

      {/* Invite Friend Modal */}
      {inviteFriendModalOpen ? (
        <InviteFriendModal
          isOpen={inviteFriendModalOpen}
          toggle={() => setInviteFriendModalOpen(!inviteFriendModalOpen)}
        />
      ) : null}

      {/* Finding Match Modal */}
      {findingMatchModalOpen ? (
        <FindingMatchModal
          isOpen={findingMatchModalOpen}
          toggle={() => setFindingMatchModalOpen(!findingMatchModalOpen)}
        />
      ) : null}
    </>
  );
};

export default MatchMakingModal;
