import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../hooks/auth.hooks";
import firebase from "../../../../config/firebase";
import { loggerService } from "../../../../helpers";
import { LOG_TYPES } from "../../../../constants";
import DefaultProfilePic from "../../../../assets/users/default-user-image.png";
import "./index.scss";

/**
 * Used to render a list item containing user profile
 * and username.
 *
 * @param {array} props.userId User ID to fetch data for
 */
const ListItem = props => {
  const auth = useAuth();
  const [userProfileUrl, setUserProfileUrl] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    const rootRef = firebase.database().ref("userInfo");
    const userRef = rootRef.child(props.userId);
    userRef.once("value", snapshot => {
      if (snapshot.val()) {
        const val = snapshot.val();

        if (val.username) setUsername(val.username);
        if (val.profileImageUrl) setUserProfileUrl(val.profileImageUrl);
      }
    });
  }, [props.userId]);

  const acceptFriendRequest = () => {
    try {
      if (!auth.user.uid) throw new Error("User.uid not found.");
      const rootRef = firebase.database().ref("friends");

      // Ref for updating current users friend node
      const currentUserRef = rootRef.child(auth.user.uid);
      currentUserRef
        .child(props.userId)
        .set({
          username,
          userId: props.userId
        })
        .catch(error => {
          throw new Error("Error when setting current user friend...", error);
        });

      // Ref for updating friends friend node
      const opposingUserRef = rootRef.child(props.userId);
      opposingUserRef
        .child(auth.user.uid)
        .set({
          username: auth.user.displayName,
          userId: auth.user.uid
        })
        .catch(error => {
          throw new Error("Error when setting opposing user friend...", error);
        });

      // At the end of adding friend we want to remove the pending, so we
      // call declineFriendRequest which in turn removes the node from
      // pendingFriendRequests
      declineFriendRequest();
    } catch (error) {
      loggerService("Error accepting friend...", error, LOG_TYPES.ERROR);
    }
  };

  const declineFriendRequest = () => {
    try {
      if (!auth.user.uid) {
        throw new Error("User.uid not found...");
      }
      // A decline means we destroy the request nodes for both users
      const rootRef = firebase.database().ref("pendingFriendRequests");

      // Current users request node
      const currentUserReqRef = rootRef
        .child(auth.user.uid)
        .child("incoming")
        .child(props.userId);
      currentUserReqRef.remove();

      // Opposing users request node
      const opposingUserReqRef = rootRef
        .child(props.userId)
        .child("outgoing")
        .child(auth.user.uid);
      opposingUserReqRef.remove();
    } catch (error) {
      loggerService(
        "Error when removing request nodes...",
        error,
        LOG_TYPES.ERROR
      );
    }
  };

  return (
    <div className="request-list-item">
      <div className="row">
        <div className="col-2">
          <div
            className="profile-picture-wrapper"
            style={{
              backgroundImage: `url(${
                userProfileUrl ? userProfileUrl : DefaultProfilePic
              })`
            }}
          ></div>
        </div>
        <div className="col-5">
          <p className="text-left pt-3">{username}</p>
        </div>
        <div className="col-5">
          <button
            style={{ minWidth: 80 }}
            className="btn lt-grey-cta mr-2 mt-2"
            onClick={acceptFriendRequest}
          >
            Accept
          </button>
          <button
            style={{ minWidth: 80 }}
            className="btn lt-grey-cta mt-2"
            onClick={declineFriendRequest}
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
