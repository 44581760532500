import React, { useEffect } from "react";
import ChatBubble from "./components/ChatBubble";
import Button from "../Button";
import { useAuth } from "../../hooks/auth.hooks";
import { useEditor } from "../../hooks/editor.hooks";
import { BUTTON_CONSTANTS } from "../../constants";
import "./index.scss";

/**
 * Chat container that loops through an array of messages
 * and renders each message. Expects a messages array with
 * the following values: username: string, createdAt: string,
 * userId: string.
 *
 * @param {Array} messages An array of messages to render
 */
const ChatContainer = ({ messages, height }) => {
  const auth = useAuth();
  const { handleAcceptInvite } = useEditor();

  useEffect(() => {
    // On message componentDidUpdate we move the scroll
    // bar down to the bottom of the DIV
    const scrollPosition = document.getElementById("messages-wrapper");
    scrollPosition.scrollTop = scrollPosition.scrollHeight;
  }, [messages]);

  const Messages = ({ x }) => {
    return x.val().isLessonInvite ? (
      <>
        <ChatBubble
          key={"coduo"}
          username={"Coduo"}
          text={x.val().text}
          createdAt={x.val().createdAt}
          userId={x.val().userId}
          currentUserId={auth.user.uid}
        />
        <Button
          styleProps={{ marginTop: 10 }}
          buttonStyle={BUTTON_CONSTANTS.GREY_TO_NAVY}
          onClick={() =>
            handleAcceptInvite(
              x.key,
              auth.user.uid,
              x.val().userId,
              x.val().lessonData.lessonType
            )
          }
          buttonText="Accept Invite"
        />
      </>
    ) : (
      <ChatBubble
        key={x.val().id}
        username={x.val().username}
        text={x.val().text}
        createdAt={x.val().createdAt}
        userId={x.val().userId}
        currentUserId={auth.user.uid}
      />
    );
  };

  return (
    <div className="chat-container-wrapper row m-0 p-0">
      <div className="col-12 p-0">
        <div className="card border-0 m-0 p-0">
          <div
            id="messages-wrapper"
            className="card border-0 m-0 p-0 position-relative bg-transparent"
            style={{ overflowY: "auto", height }}
          >
            {messages
              ? messages.map((x, i) => <Messages key={i} x={x} />)
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatContainer;
