import React, { useState, useEffect } from "react";
import BreadCrumbs from "./components/BreadCrumbs";
import LessonPanel from "./components/LessonPanel";
import LevelBox from "./components/LevelBox";
import {
  lessonData_CSS,
  lessonData_HTML,
  coursesAvailable
} from "../../config/course-data";
import "./index.scss";

const LanguageLessonPage = () => {
  const [data, setData] = useState([]);
  const [course, setCourse] = useState({});
  const urlPathArray = window.location.pathname.split("/");
  const language = urlPathArray[urlPathArray.length - 1];

  useEffect(() => {
    setCourse(coursesAvailable.filter(x => x.language === language)[0]);

    switch (language) {
      case "html":
        setData(lessonData_HTML);
        break;
      case "css":
        setData(lessonData_CSS);
        break;
      default:
        break;
    }
  }, [language]);

  return (
    <div className="language-lesson-page-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <BreadCrumbs language={language} />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-7">
            <h1>
              <small>course: </small> {language.toUpperCase()}
            </h1>
            <p>{course.description}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 order-lg-12">
            <LevelBox lessonLanguage={language} />
          </div>
          <div className="col-lg-8 order-lg-1">
            {data.map((lesson, index) => (
              <LessonPanel key={index} lesson={lesson} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageLessonPage;
