import React from "react";
import Loader from "react-loader-spinner";

const LoadingFriendInvite = () => {
  return (
    <div className="text-center">
      <h4 className="navy">Waiting for friends response...</h4>
      <Loader type="Oval" color="#85d8ce" height={80} width={80} />
    </div>
  );
};

export default LoadingFriendInvite;
