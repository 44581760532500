import firebase from "firebase";

const config = {
  apiKey: "AIzaSyDynxODET3euY4FBnNGYKQdTNMURLAyBkQ",
  authDomain: "coduo-308c4.firebaseapp.com",
  databaseURL: "https://coduo-308c4.firebaseio.com",
  projectId: "coduo-308c4",
  storageBucket: "coduo-308c4.appspot.com",
  messagingSenderId: "559910159669"
};
firebase.initializeApp(config);
export default firebase;
