import React from "react";
import firebase from "../../../../../config/firebase";
import { useList } from "react-firebase-hooks/database";
import ChatContainer from "../../../../../components/ChatContainer";
import ChatInput from "../../../../../components/ChatContainer/components/ChatInput";
import { useEditor } from "../../../../../hooks/editor.hooks";
import "./index.scss";

const RoomChat = () => {
  const { roomId } = useEditor();

  let ref = "";
  if (roomId) {
    ref = firebase
      .database()
      .ref("editorRooms")
      .child(roomId)
      .child("chat");
  }

  const [value] = useList(ref);

  return (
    <div className="container room-chat-component-wrapper">
      <div className="row">
        <div className="col-12">
          <ChatContainer messages={value} />
          <ChatInput chatRef={ref} />
        </div>
      </div>
    </div>
  );
};

export default RoomChat;
