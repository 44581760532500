import { useState } from "react";
import { useAuth } from "./auth.hooks";
import { useHistory } from "react-router-dom";
import { FORM_TYPES } from "../constants";
import firebase from "../config/firebase";

export const useForm = formType => {
  const history = useHistory();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [failuredMessage, setFailureMessage] = useState("");
  const [inputs, setInputs] = useState({
    username: "",
    email: "",
    password: "",
    bug: ""
  });

  const handleSubmit = async event => {
    if (event) event.preventDefault();

    setIsLoading(true);

    if (!Object.keys(inputs).length) setIsFailure(true);

    switch (formType) {
      case FORM_TYPES.SIGN_UP_FORM:
        handleSignUpFormSubmit(inputs);
        break;

      case FORM_TYPES.LOG_IN_FORM:
        handleLogInFormSubmit(inputs);
        break;

      case FORM_TYPES.BUG_FORM:
        handleBugFormSubmit(inputs);
        break;

      case FORM_TYPES.USERNAME_CHANGE_FORM:
        handleUsernameChangeFormSubmit(inputs);
        break;

      case FORM_TYPES.DELETE_ACCOUNT_FORM:
        handleDeleteAccountFormSubmit(inputs);
        break;

      default:
        break;
    }
  };

  const handleSignUpFormSubmit = async ({ email, password, username }) => {
    try {
      const user = await auth.signUp(email, password, username);
      if (user) {
        setIsSuccess(true);
        setIsLoading(false);
        history.push("/courses");
      }
    } catch (error) {
      setIsFailure(true);
      setFailureMessage(error.message);
      setIsLoading(false);
    }
  };

  const handleLogInFormSubmit = async ({ email, password }) => {
    try {
      const user = await auth.signIn(email, password);
      if (user) {
        setIsSuccess(true);
        setIsLoading(false);
        history.push("/courses");
      }
    } catch (error) {
      setIsFailure(true);
      setFailureMessage(error.message);
      setIsLoading(false);
    }
  };

  const handleBugFormSubmit = async ({ bug }) => {
    try {
      const rootRef = firebase.database().ref("bugsReported");
      await rootRef.push(bug);
      setIsSuccess(true);
      setIsLoading(false);
    } catch (error) {
      setIsFailure(true);
      setFailureMessage(error.message);
      setIsLoading(false);
    }
  };

  const handleUsernameChangeFormSubmit = async ({ username }) => {
    if (auth && auth.user) {
      try {
        await auth.updateProfileUsername(auth.user, username);
        setIsSuccess(true);
        setIsLoading(false);
      } catch (error) {
        setIsFailure(true);
        setFailureMessage(error.message);
        setIsLoading(false);
      }
    }
  };

  const handleDeleteAccountFormSubmit = async ({ email, password }) => {
    if (auth && auth.user) {
      try {
        await auth.reAuthenticateUser(email, password);
        await auth.user.delete();
        setIsSuccess(true);
        setIsLoading(false);
        history.push("/");
      } catch (error) {
        setIsFailure(true);
        setFailureMessage(error.message);
        setIsLoading(false);
      }
    }
  };

  const handleInputChange = event => {
    event.persist();
    setIsLoading(false);
    setIsSuccess(false);
    setIsFailure(false);
    setFailureMessage("");
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };

  return {
    handleSubmit,
    handleInputChange,
    inputs,
    isLoading,
    isSuccess,
    isFailure,
    failuredMessage
  };
};
