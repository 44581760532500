import React from "react";
import SignupForm from "../../components/SignupForm";
import MediaBody from "./components/MediaBody";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faCode, faFileCode } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";

const HomePage = () => {
  return (
    <div className="home-page-wrapper navy-to-teal-bg">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-5 order-md-2 mb-3 mb-sm-0">
            <SignupForm />
          </div>
          <div className="col-12 col-md-7 order-md-1">
            <h3 className="white mb-4">Learn to code and change your life.</h3>
            <h4 className="white mb-4">
              Coduo is the one stop shop for new developers to connect, learn,
              and grow
            </h4>
            <MediaBody
              faIcon={<FontAwesomeIcon size={"lg"} icon={faUsers} />}
              header={"SOCIAL NETWORKING"}
              body={
                "Add friends and connect with other motivated developers as you complete courses together."
              }
            />
            <MediaBody
              faIcon={<FontAwesomeIcon size={"lg"} icon={faCode} />}
              header={"SHARED CODE EDITOR"}
              body={
                "Work on the same code as your partners using the online shared text editor for simple, remote, paired programming."
              }
            />
            <MediaBody
              faIcon={<FontAwesomeIcon size={"lg"} icon={faFileCode} />}
              header={"COURSES"}
              body={
                "Work with a friend, or get paired with someone of similar skills to complete course lessons and improve your skills."
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
