import React from "react";
import SignupForm from "../../components/SignupForm";
import "./index.scss";

const SignupPage = () => {
  return (
    <div className="signup-page-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <SignupForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
